<div class="app-content content login-page">
  <div class="content-wrapper">
    <div class="content-body">
      <section class="flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-md-6 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
              <div class="card-header border-0 pb-0">
                <div class="card-title text-center">
                  <img
                    class="login-logo"
                    src="assets/images/logo/logo.svg"
                    alt="branding logo"
                  />
                </div>
                <h6
                  class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2 mb-0"
                >
                  <span *ngIf="this.submitted == false">Sign Documents</span>
                  <span *ngIf="this.submitted == true"
                    >Select Transition Lead</span
                  >
                </h6>

                <div class="card-content">
                  <!-- if documents not signed, then sign them first -->
                  <ng-container *ngIf="this.submitted == false">
                    <div class="card-body" *ngIf="!loading">
                      <p>
                        Review and accept policies for:
                        <strong
                          >{{ currentUser.school.establishment_name }}</strong
                        >
                      </p>
                      <p>
                        SixIntoSeven uses sensitive data to securely transmit
                        progress information between schools. To comply with
                        GDPR, all participating schools must accept the Data
                        Sharing Agreement and Platform License Agreement.
                      </p>
                      <p class="mb-0">
                        Please review the agreements below and tick to confirm
                        that you, as a Headteacher or Deputy Headteacher, accept
                        the terms. Users added after this point will not need to
                        accept additional policies. If you have any questions,
                        please
                        <a
                          target="_blank"
                          href="https://sixintoseven.co.uk/help/"
                          >contact us</a
                        >
                      </p>
                      <div
                        class="row mt-2 mb-2 align-content-center"
                        *ngFor="let document of documents"
                      >
                        <div class="col">
                          <a (click)="previewDocument(document)">
                            <strong>{{ document.name }}</strong>
                          </a>
                        </div>
                        <div class="col col-auto text-right">
                          <i
                            *ngIf="document.signed"
                            class="fa fa-check-circle text-success fs-3"
                          ></i>
                          <button
                            *ngIf="!document.signed"
                            class="btn btn-size btn-success"
                            (click)="previewDocument(document)"
                          >
                            View &amp; Accept
                          </button>
                        </div>
                      </div>
                      <div class="row justify-content-center">
                        <button
                          *ngIf="documentsSigned"
                          [disabled]="!allDocumentsSigned()"
                          type="submit"
                          class="btn btn-size btn-success btn-block mt-3"
                          (click)="submitDocuments()"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </ng-container>
                  <!-- if documents have been signed, then make the user select a transition lead -->
                  <ng-container *ngIf="this.submitted == true">
                    <div class="card-body" *ngIf="!loading">
                      <div class="form-group">
                        <label
                          ><strong
                            >Select a Transition Lead Option</strong
                          ></label
                        >

                        <div class="form-check">
                          <label class="form-check-label"
                            ><input
                              class="form-check-input"
                              type="radio"
                              name="uploadType"
                              value="transitionLead"
                              (change)="changeLeadType()"
                              [(ngModel)]="leadType"
                            />
                            I am the transition lead and would like to proceed
                            to the SixIntoSeven portal
                          </label>
                        </div>
                        <br />
                        <div class="form-check">
                          <label class="form-check-label"
                            ><input
                              class="form-check-input"
                              type="radio"
                              name="uploadType"
                              value="notTransitionLead"
                              [(ngModel)]="leadType"
                              (change)="changeLeadType()"
                            />
                            I would like to provide details of the transition
                            lead to manage SixIntoSeven on my behalf
                          </label>
                        </div>

                        <ng-container *ngIf="leadType == 'transitionLead'">
                          <div class="row justify-content-center">
                            <button
                              type="submit"
                              class="btn btn-size btn-success btn-block mt-3"
                              routerLink="/school/dashboard"
                            >
                              Login to Portal
                            </button>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="leadType == 'notTransitionLead'">
                          <br />
                          <form
                            class="form-horizontal"
                            [formGroup]="transitionLeadForm"
                          >
                            <fieldset
                              class="form-group position-relative has-icon-left"
                            >
                              <input
                                type="text"
                                id="forename"
                                formControlName="forename"
                                class="form-control"
                                [ngClass]="{
                                  'is-invalid':
                                    submittedLead && f.forename.errors
                                }"
                                placeholder="Forename"
                              />
                              <div class="form-control-position">
                                <i class="feather ft-user"></i>
                              </div>
                              <div
                                *ngIf="submittedLead && f.forename.errors"
                                class="invalid-feedback"
                              >
                                <div *ngIf="f.forename.errors.required">
                                  Forename is required
                                </div>
                              </div>
                            </fieldset>

                            <fieldset
                              class="form-group position-relative has-icon-left"
                            >
                              <input
                                type="text"
                                formControlName="surname"
                                class="form-control"
                                [ngClass]="{
                                  'is-invalid':
                                    submittedLead && f.surname.errors
                                }"
                                placeholder="Surname"
                              />
                              <div class="form-control-position">
                                <i class="feather ft-user"></i>
                              </div>
                              <div
                                *ngIf="submittedLead && f.surname.errors"
                                class="invalid-feedback"
                              >
                                <div *ngIf="f.surname.errors.required">
                                  Surname is required
                                </div>
                              </div>
                            </fieldset>

                            <fieldset
                              class="form-group position-relative has-icon-left"
                            >
                              <input
                                type="email"
                                formControlName="email"
                                class="form-control"
                                email
                                [ngClass]="{
                                  'is-invalid': submittedLead && f.email.errors
                                }"
                                placeholder="Email Address"
                                required
                              />
                              <div class="form-control-position">
                                <i class="feather ft-mail"></i>
                              </div>

                              <div
                                *ngIf="submittedLead && f.email.errors"
                                class="invalid-feedback"
                              >
                                <div *ngIf="f.email.errors.required">
                                  Email is required
                                </div>
                              </div>
                            </fieldset>

                            <div
                              *ngIf="emailSentToTransitionLead == false"
                              class="justify-content-enter"
                            >
                              <button
                                (click)="addTransitionLead()"
                                type="submit"
                                class="btn btn-size btn-success col-4 mb-1"
                              >
                                Send Email to Transition Lead
                              </button>
                            </div>
                            <div
                              *ngIf="emailSentToTransitionLead == true"
                              class="justify-content-enter"
                            >
                              <button
                                (click)="goToLogin()"
                                type="submit"
                                class="btn btn-size btn-success col-4 mb-1 login-button"
                              >
                                Login to Portal
                              </button>

                              <button
                                (click)="loginPage()"
                                type="submit"
                                class="btn btn-size btn-secondary col-4 mb-1 login-button"
                              >
                                Close
                              </button>
                            </div>
                          </form>

                          <ng-container
                            *ngIf="emailSentToTransitionLead == true"
                          >
                            <div>
                              Your transition lead will receive an email from
                              noreply@SixIntoSeven.com. This email contains the
                              link to verify their email and access
                              SixIntoSeven.
                            </div>
                            <div style="font-style: italic;">
                              Please note: we advise Inbox/Spam/Junk folders are
                              monitored and if your transition lead does not
                              receive an email after 2 hours please contact:
                              support@sixintoseven.co.uk
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                  <app-loader *ngIf="loading"></app-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
