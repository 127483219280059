import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class DateHelperService {
  constructor() {}

  formatDate(date) {
    return moment(date).format("DD/MM/YYYY").toString();
  }

  formatTime(date) {
    return moment(date).format("HH:mm").toString();
  }

  dateToObject(original: Date) {
    return {
      year: moment(original).year(),
      month: parseInt(moment(original).format("M")),
      day: moment(original).date(),
    };
  }

  objectToDate(dateObject: DateObject) {
    dateObject.month = dateObject.month - 1;
    return moment(dateObject).toDate();
  }

  getYear(date) {
    return moment(date).year();
  }

  getUKStringDate(date) {
    date = new Date(date);

    let options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    date = date.toLocaleString("en-UK", options);
    return date;
  }
}

class DateObject {
  year: number;
  month: number;
  day: number;
}
