<div class="modal-header">
  <h4 class="modal-title">Select Version</h4>
  <a (click)="close()"
    ><i class="fa fa-times tutorial-close text-success"></i>
  </a>
</div>
<div class="modal-body px-0">
  <ng-container *ngIf="!loading">
    <p-table
      #dt1
      [columns]="cols"
      [scrollable]="true"
      [value]="versions"
      [rows]="100"
      scrollHeight="400px"
      [virtualScroll]="true"
      sortField="name"
      sortOrder="1"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">
            Version
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th>Action</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr [pSelectableRow]="row">
          <td>
            {{ dateHelper.getYear(row.end_date) }}
          </td>

          <td class="text-right">
            <button
              type="button"
              class="btn btn-size btn-success"
              (click)="selectAcademicYear(row)"
            >
              Select
            </button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="2">No versions found</td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>

  <app-loader *ngIf="loading"></app-loader>
</div>
