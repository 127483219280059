import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CardModule } from "../../pages/partials/general/card/card.module";
import { RouterModule } from "@angular/router";
import { BreadcrumbComponent } from "./breadcrumb.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [CommonModule, CardModule, NgbModule, RouterModule.forChild([])],
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}
