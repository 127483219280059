import { Component, Input, OnInit } from "@angular/core";
import { InvitesService } from "../../../_api/invites/invites.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ErrorService } from "../../../_services/global/error.service";
import { SchoolsService } from "../../../_services/schools/schools.service";
import { UsersService } from "../../../_services/users/users.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  FormControl,
  AbstractControl,
} from "@angular/forms";
// import { ThrowStmt } from "@angular/compiler";
import { AuthService } from "src/app/_services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-register-password-login-modal",
  templateUrl: "./register-password-login-modal.component.html",
  styleUrls: ["./register-password-login-modal.component.css"],
})
export class RegisterPasswordLoginModalComponent implements OnInit {
  loginForm: FormGroup;
  currentUser;
  submitted = false;
  viewPassword = false;
  viewConfirmPassword = false;

  passwordNotMatch = false;

  user: any;
  passwordRegex =
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
  passwordRegexNotMet = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private invitesService: InvitesService,
    private errorService: ErrorService,
    private schoolsService: SchoolsService,
    private usersService: UsersService,
    public authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.loginForm = this.formBuilder.group({
      email: [null, Validators.required],
      password: [
        null,
        [Validators.required, Validators.pattern(this.passwordRegex)],
      ],
      confirm_password: [
        null,
        [Validators.required, Validators.pattern(this.passwordRegex)],
      ],
    });
  }

  toggleViewPassword() {
    this.viewPassword = !this.viewPassword;
  }

  toggleViewConfirmPassword() {
    this.viewConfirmPassword = !this.viewConfirmPassword;
  }

  async login() {
    this.submitted = true;
    //if form has errors, then return
    if (this.loginForm.invalid) {
      return;
    }
    let value = {
      email: this.loginForm.value.email.toLowerCase().trim(),
      password: this.loginForm.value.password,
    };

    this.activeModal.close(value);
  }

  close() {
    this.activeModal.close(false);
  }

  get f() {
    return this.loginForm.controls;
  }
}
