import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(private http: HttpClient) {}

  //get all
  index() {
    return this.http
      .get<any>(environment.api.url + "/notifications")
      .toPromise();
  }

  //other functions
  getBySchool(schoolID: string) {
    return this.http
      .get(environment.api.url + "/notifications/by-school/" + schoolID)
      .toPromise();
  }

  getByGroup(groupID: string) {
    return this.http
      .get(environment.api.url + "/notifications/by-group/" + groupID)
      .toPromise();
  }

  markAsRead(notificationIDs: any) {
    return this.http
      .post(
        environment.api.url + "/notifications/mark-as-read",
        notificationIDs
      )
      .toPromise();
  }

  addNotification(model: any) {
    return this.http
      .post(environment.api.url + "/notifications/add-notification", model)
      .toPromise();
  }
}
