<div class="app-content content login-page">
  <div class="container" id="container">
    <div class="form-container sign-up-container">
      <form
        [formGroup]="loginForm"
        (ngSubmit)="tryLogin()"
        class="justify-content"
      >
        <div class="row mt-1 mb-4">
          <img
            class="login-logo"
            src="assets/images/logo/logo.svg"
            alt="branding logo"
          />
        </div>
        <div class="row justify-content-center mt-4">
          <p>
            Website: <a href="http://sixintoseven.co.uk">sixintoseven.co.uk</a
            ><br />
            Email: support@sixintoseven.co.uk
          </p>
        </div>
      </form>
    </div>

    <div class="form-container sign-in-container">
      <ng-container *ngIf="errors && errors.length">
        <div class="bs-callout-danger callout-border-left pl-1" role="alert">
          <div class="alert-text" *ngFor="let error of errors">{{ error }}</div>
        </div>
      </ng-container>
      <form
        [formGroup]="loginForm"
        (ngSubmit)="tryLogin()"
        class="justify-content"
      >
        <div class="row">
          <img
            class="login-logo mt-1"
            src="assets/images/logo/logo.svg"
            alt="branding logo"
          />
        </div>
        <div class="row justify-content-center">
          <div *ngIf="invite && invite.school">
            <p>
              Your school
              <strong
                >{{ invite.school.establishment_name }},
                {{ invite.school.street }}, {{ invite.school.postcode }}</strong
              >
              has been invited to join SixIntoSeven by
              <strong
                >{{ invite.invite_user.forename }}
                {{ invite.invite_user.surname }}</strong
              >
              <ng-container *ngIf="invite.invite_school">
                from
                <strong>{{ invite.invite_school.establishment_name }}</strong>
              </ng-container>
            </p>
            <div
              class="bs-callout-danger callout-border-left callout-transparent pl-1 mb-1"
            >
              <p>
                <strong>Please Note:</strong> Registration should only be
                completed by the Headteacher/Deputy Headteacher of
                <strong>
                  {{ invite.school.establishment_name }},
                  {{ invite.school.street }}, {{ invite.school.postcode }}.
                </strong>
                Once registered additional users can be added.
              </p>
            </div>
            <p>
              Login to add
              <strong>
                {{ invite.school.establishment_name }},
                {{ invite.school.street }}, {{ invite.school.postcode }}
              </strong>
              to your existing account.
            </p>
          </div>
          <div class="login-container">
            <div class="pb-lg-0 pb-3">
              <button
                type="button"
                class="btn btn-clear google-login"
                (click)="googleLogin()"
              >
                <img
                  src="assets/media/logos/btn_google_signin_light_normal_web@2x.png"
                  alt="Sign in with Google"
                  class="google-logo"
                />
              </button>

              <button class="btn" (click)="login()">
                <img
                  src="assets/media/logos/ms-symbollockup_signin_light.svg"
                  alt="Sign in with Microsoft"
                />
              </button>

              <button class="btn" (click)="LGFLlogin()">
                <img
                  src="assets/media/logos/other-login.png"
                  alt="Sign in with Other"
                  style="width: 220px"
                />
              </button>
            </div>
          </div>
          <div class="row justify-content-center pt-3 pb-0">
            Copyright SixIntoSeven © All Rights Reserved 2022
            <a href="assets/files/SixIntoSeven-Privacy-2022.pdf"
              >Privacy Policy</a
            >
          </div>
        </div>
      </form>
    </div>

    <div class="overlay-container">
      <div class="overlay">
        <div class="overlay-panel overlay-left">
          <h1 class="overlay-title">Ready to Sign in?</h1>
          <p>To navigate back to the login screen click below.</p>
          <button
            class="ghost login"
            id="signIn"
            (click)="toggleSign('signIn')"
          >
            Sign In
          </button>
        </div>
        <div class="overlay-panel overlay-right">
          <h1 class="overlay-title">Welcome!</h1>
          <p>
            Select to sign in with Google, Microsoft or Other, depending on your
            email account. The email must match the address you received from
            SixIntoSeven to register
          </p>
          <br />
          <p>For support or further information, please click below.</p>
          <button
            class="ghost login"
            id="signUp"
            (click)="toggleSign('signUp')"
          >
            Support
          </button>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>
