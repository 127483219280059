import { APP_INITIALIZER, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CheckboxModule } from "primeng/checkbox";
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from "@angular/platform-browser";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  NgbModule,
  NgbCarouselConfig,
  NgbModalConfig,
  NgbDateParserFormatter,
  NgbNavModule,
} from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthGuard } from "./_guards/auth.guard";
import { AlertComponent } from "./_helpers/alert.component";
import { AlertService } from "./_services/alert.service";
import { AuthService } from "./_services/auth.service";
import { ChartApiService } from "./_services/chart.api";
import { TableApiService } from "./_services/table-api.service";
import { ApplicationApiService } from "./_services/application-api.service";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
// Routing
import { AppRoutingModule } from "./app.routing";
// Components
import { AppComponent } from "./app.component";
import { SettingsModule } from "./_layout/settings/settings.module";
import { ThemeSettingsConfig } from "./_layout/settings/theme-settings.config";
import { MenuSettingsConfig } from "./_layout/settings/menu-settings.config";
import { HeaderComponent } from "./_layout/header/header.component";
import {
  VerticalComponent as HeaderVerticalComponent,
  VerticalComponent,
} from "./_layout/header/vertical/vertical.component";
import { HorizontalComponent as HeaderHorizontalComponent } from "./_layout/header/horizontal/horizontal.component";
import { FullLayoutNavbarComponent } from "./_layout/header/full-layout-navbar/full-layout-navbar.component";
import { FooterComponent } from "./_layout/footer/footer.component";
import { NavigationComponent as AppNavigationComponent } from "./_layout/navigation/navigation.component";
import { PublicLayoutComponent } from "./_layout/public-layout/public-layout.component";
import { PrivateLayoutComponent } from "./_layout/private-layout/private-layout.component";
import { LoginComponent } from "./pages/login";
import { ForgotPasswordComponent } from "./pages/login/forgot-password/forgot-password.component";

import { NavbarService } from "./_services/navbar.service";
import { VerticalnavComponent } from "./_layout/navigation/verticalnav/verticalnav.component";
import { HorizontalnavComponent } from "./_layout/navigation/horizontalnav/horizontalnav.component";
// perfect scroll bar
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
// spinner
import { NgxSpinnerModule } from "ngx-spinner";
// import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { DeviceDetectorModule } from "ngx-device-detector";
import { CustomizerComponent } from "./_layout/customizer/customizer.component";
import { ChartsModule } from "ng2-charts";
import { PartialsModule } from "./pages/partials/partials.module";
import { BreadcrumbModule } from "./_layout/breadcrumb/breadcrumb.module";
import { HorizontalCustomizerComponent } from "./_layout/customizer/horizontal-customizer/horizontal-customizer.component";
import { BlockTemplateComponent } from "./_layout/blockui/block-template.component";
import { BlockUIModule } from "ng-block-ui";
import { MatchHeightModule } from "./pages/partials/general/match-height/match-height.module";
import { FullLayoutComponent } from "./_layout/full-layout/full-layout.component";
import { ToastrModule } from "ngx-toastr";
import { DeleteModalComponent } from "./_components/modals/delete-modal/delete-modal.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { ArchwizardModule } from "angular-archwizard";
import { JwtModule } from "@auth0/angular-jwt";
import { AuthInterceptor } from "./_helpers/auth.interceptor";
import { LgflLoginModalComponent } from "./_components/modals/lgfl-login-modal/lgfl-login-modal.component";
import { RegisterPasswordLoginModalComponent } from "./_components/modals/register-password-login-modal/register-password-login-modal.component";

import { NgbDateMomentParserFormatter } from "./_helpers/date-format";
import { DocumentModalComponent } from "./_components/modals/document-modal/document-modal.component";
import { RequestModalComponent } from "./_components/modals/request-modal/request-modal.component";
import { SchoolsContactDetailsModalComponent } from "./_components/modals/schools-contact-details-modal/schools-contact-details-modal.component";

import { DocumentsSignComponent } from "./pages/register-school/documents-sign/documents-sign.component";

import { PasswordResetComponent } from "./pages/password-reset/password-reset.component";
import { RegisterSchoolComponent } from "./pages/register-school/register-school.component";

import { LoaderModule } from "./_components/loader/loader.module";
import { PendingChangesGuard } from "./_guards/pending-changes.guard";
import { AcceptLinkedSchoolModalComponent } from "./_components/modals/accept-linked-school-modal/accept-linked-school-modal.component";
import { AcademicYearSelectorModalComponent } from "./_components/modals/academic-year-selector-modal/academic-year-selector-modal.component";
import { MapTablesModalComponent } from "./_components/modals/map-tables-modal/map-tables-modal.component";
import { CohortOverviewModalComponent } from "./_components/modals/cohort-overview-modal/cohort-overview-modal.component";
import { TransitionSummaryCSVModalComponent } from "./_components/modals/transition-summary-csv-modal/transition-summary-csv-modal.component";
import { SurveyAnalysisModalComponent } from "./_components/modals/survey-analysis-modal/survey-analysis-modal.component";

import { TableModule } from "primeng/table";
import { AttainmentKeyModalComponent } from "./_components/modals/attainment-key-modal/attainment-key-modal.component";
import { AttainmentSelectAllModalComponent } from "./_components/modals/attainment-select-all-modal/attainment-select-all-modal.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { SaveModalComponent } from "./_components/modals/save-modal/save-modal.component";
import { SaveProgressModalComponent } from "./_components/modals/save-progress-modal/save-progress-modal.component";
import { DownloadUploadModalComponent } from "./_components/modals/download-upload-modal/download-upload-modal.component";
import { SupportRequestModalComponent } from "./_components/modals/support-request-modal/support-request-modal.component";
import { MsalModule, MsalInterceptor } from "@azure/msal-angular";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  SocialLoginModule,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from "angularx-social-login";
import { environment } from "src/environments/environment";
import { InformationModalModalComponent } from "./_components/modals/information-modal-modal/information-modal-modal.component";
import { StudentAttachmentsModalComponent } from "./_components/modals/student-attachments-modal/student-attachments-modal.component";
import { StudentAttachmentsPreviewModalComponent } from "./_components/modals/student-attachments-preview-modal/student-attachments-preview-modal.component";
import { DownloadSharedDataModalComponent } from "./_components/modals/download-shared-data-modal/download-shared-data-modal.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { PreviewPDFModalComponent } from "./_components/modals/preview-pdf-modal/preview-pdf-modal.component";
import { TooltipModule } from "primeng/tooltip";
import { DataCategoriesOrderModalComponent } from "./_components/modals/data-categories-order-modal/data-categories-order-modal.component";
import { EditorModule } from "@tinymce/tinymce-angular";
import { RatingModule } from "primeng/rating";
import { SurveyModalComponent } from "./_components/modals/survey-modal/survey-modal.component";
import { DownloadSignedAgreementsModalComponent } from "./_components/modals/download-signed-agreements-modal/download-signed-agreements-modal.component";
import { MaintenanceComponent } from "./pages/maintenance/maintenance.component";

export function tokenGetter() {
  return sessionStorage.getItem("access_token");
}

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    CommonModule,
    FontAwesomeModule,
    BrowserModule,
    PartialsModule,
    ReactiveFormsModule,
    CheckboxModule,
    HttpClientModule,
    ChartsModule,
    BrowserAnimationsModule,
    MatchHeightModule,
    BreadcrumbModule,
    NgbModule,
    FormsModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.msal.clientId, // This is your client ID
          authority: "https://login.microsoftonline.com/common", // This is your tenant ID
          redirectUri: environment.msal.redirectUri, // This is your redirect URI
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
      }),
      null,
      null
    ),
    SocialLoginModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    // AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AppRoutingModule,
    // Settings modules
    SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    DeviceDetectorModule.forRoot(),
    // LoadingBarRouterModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent,
    }),

    NgSelectModule,
    ArchwizardModule,
    EditorModule,
    RatingModule,
    NgbNavModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: [
          "localhost:3100",
          "sales.api.sixintoseven.co.uk",
          "legacy.api.sixintoseven.co.uk",
          "staging.api.sixintoseven.co.uk",
          "api.sixintoseven.co.uk",
        ],
        blacklistedRoutes: [
          "localhost:3100/login",
          "sales.api.sixintoseven.co.uk/login",
          "legacy.api.sixintoseven.co.uk/login",
          "staging.api.sixintoseven.co.uk/login",
          "api.sixintoseven.co.uk/login",
        ],
      },
    }),
    LoaderModule,
    TableModule,
    PdfViewerModule,
    TooltipModule,
  ],
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,
    HeaderComponent,
    FullLayoutNavbarComponent,
    HeaderHorizontalComponent,
    HeaderVerticalComponent,
    FooterComponent,
    AppNavigationComponent,
    AlertComponent,
    LoginComponent,
    ForgotPasswordComponent,
    VerticalnavComponent,
    HorizontalnavComponent,
    CustomizerComponent,
    HorizontalCustomizerComponent,
    BlockTemplateComponent,
    FullLayoutComponent,
    DeleteModalComponent,
    DocumentModalComponent,
    RequestModalComponent,
    LgflLoginModalComponent,
    RegisterPasswordLoginModalComponent,
    DocumentsSignComponent,
    PasswordResetComponent,
    RegisterSchoolComponent,
    AcceptLinkedSchoolModalComponent,
    SchoolsContactDetailsModalComponent,
    AcademicYearSelectorModalComponent,
    MapTablesModalComponent,
    TransitionSummaryCSVModalComponent,
    SurveyAnalysisModalComponent,
    CohortOverviewModalComponent,
    AttainmentKeyModalComponent,
    AttainmentSelectAllModalComponent,
    SaveModalComponent,
    SaveProgressModalComponent,
    DownloadUploadModalComponent,
    SupportRequestModalComponent,
    InformationModalModalComponent,
    StudentAttachmentsModalComponent,
    StudentAttachmentsPreviewModalComponent,
    DownloadSharedDataModalComponent,
    DownloadSignedAgreementsModalComponent,
    PreviewPDFModalComponent,
    DataCategoriesOrderModalComponent,
    SurveyModalComponent,
    MaintenanceComponent,
  ],
  providers: [
    AuthGuard,
    PendingChangesGuard,
    ChartApiService,
    AlertService,
    NavbarService,
    TableApiService,
    ApplicationApiService,
    AuthService,
    /**
     * Google Login Provider
     */
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.google.clientId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    /**
     * Microsoft Login Provider
     */
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true,
    // },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig,
    },

    NgbCarouselConfig,
    NgbModalConfig,
    {
      provide: NgbDateParserFormatter,
      useFactory: () => new NgbDateMomentParserFormatter("DD/MM/YYYY"),
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  //entryComponents:[BlockTemplateComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
