<div class="card tutorial-card">
  <div class="card-content">
    <div class="card-body tutorial-header" *ngIf="!loading">
      <div class="card-header border-0 pb-0 mb-0">
        <div class="card-title d-flex justify-content-between">
          <div></div>
          <div>
            <img
              class="login-logo"
              src="assets/images/logo/logo.svg"
              alt="branding logo"
            />
          </div>
          <div>
            <a (click)="close()"
              ><i
                class="fa fa-times tutorial-close col col-auto text-success"
              ></i>
            </a>
          </div>
        </div>

        <h6
          class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"
        >
          <span>Survey</span>
        </h6>
      </div>
      <div class="row">
        <div class="tutorial-step-counter col">
          <ng-container *ngIf="!submitted && proceed && surveyField"
            >Questions {{ activeTab + 1 }} of {{ surveyField.fields.length
            }}</ng-container
          >
        </div>
      </div>
    </div>
    <div class="card-body px-3 pb-3" *ngIf="surveyField">
      <ng-container *ngIf="!loading && !proceed">
        <div class="tutorial-step-header row">
          <h2 class="col my-auto">Welcome back {{ currentUser.forename }}</h2>
        </div>
        <br />
        <h3><div [innerHTML]="surveyField.welcome_note"></div></h3>
        <div class="d-flex justify-content-between">
          <div>
            <button
              class="btn btn-size btn btn-size btn-outline-secondary"
              (click)="close()"
            >
              Remind me later
            </button>
          </div>
          <div>
            <button
              class="btn btn-size btn btn-size btn-success"
              (click)="proceedWithSurvey()"
            >
              Start
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading && !submitted && proceed">
        <div [ngbNavOutlet]="tutorialNav"></div>
        <ul ngbNav #tutorialNav="ngbNav" [(activeId)]="activeTab">
          <ng-container *ngFor="let field of surveyField.fields; let i = index">
            <li [ngbNavItem]="i">
              <ng-template ngbNavContent>
                <div class="tutorial-step-header row">
                  <form [formGroup]="surveyForm">
                    <div class="form-group">
                      <label
                        class="form-label"
                        *ngIf="field.type !== 'null' && field.type !== 'title'"
                      >
                        <h3>
                          {{ field.label }}
                          <span class="text-danger" *ngIf="!field.optional"
                            >*</span
                          >
                        </h3>
                      </label>
                      <br
                        *ngIf="field.type !== 'null' || field.type !== 'title'"
                      />

                      <h3
                        *ngIf="field.type === 'null' || field.type === 'title'"
                      >
                        {{ field.label }}
                      </h3>
                      <!-- <small class="text-muted">{{ field.description }}</small> -->

                      <input
                        *ngIf="field.type === 'text'"
                        type="text"
                        class="form-control"
                        formControlName="{{ field.key }}"
                        [class.is-invalid]="invalidForm && f[field.key].errors"
                      />

                      <textarea
                        *ngIf="field.type === 'textarea'"
                        class="form-control"
                        rows="5"
                        cols="4"
                        formControlName="{{ field.key }}"
                        [class.is-invalid]="invalidForm && f[field.key].errors"
                      ></textarea>
                      <input
                        *ngIf="field.type === 'number'"
                        type="number"
                        class="form-control"
                        formControlName="{{ field.key }}"
                        [class.is-invalid]="invalidForm && f[field.key].errors"
                      />
                      <div
                        [class.is-invalid]="invalidForm && f[field.key].errors"
                      >
                        <editor
                          apiKey="9jquz3rm362x4jyf7u5zzz0g070rktygdy9z8909lv76phw0"
                          [init]="{
                            height: 500,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                              'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help'
                          }"
                          *ngIf="field.type === 'editor'"
                          formControlName="{{ field.key }}"
                          [disabled]="f[field.key].disabled"
                        ></editor>
                      </div>

                      <div class="input-group" *ngIf="field.type === 'date'">
                        <input
                          formControlName="{{ field.key }}"
                          ngbDatepicker
                          #fieldDate="ngbDatepicker"
                          type="text"
                          class="form-control"
                          placeholder="Select Date"
                          (click)="fieldDate.toggle()"
                          [class.is-invalid]="
                            invalidForm && f[field.key].errors
                          "
                        />
                        <div
                          class="input-group-append"
                          *ngIf="!f[field.key].disabled"
                        >
                          <div
                            class="input-group-text bg-primary"
                            (click)="fieldDate.toggle()"
                          >
                            <i
                              class="fa fa-calendar text-white"
                              style="cursor: pointer;"
                            ></i>
                          </div>
                        </div>
                      </div>
                      <ng-select
                        *ngIf="field.type === 'dropdown'"
                        formControlName="{{ field.key }}"
                        placeholder="{{ field.description }}"
                        required
                        [items]="field.options"
                        bindLabel="label"
                        bindValue="value"
                        [class.is-invalid]="invalidForm && f[field.key].errors"
                      >
                      </ng-select>
                      <br />
                      <div
                        class="form-check"
                        [class.is-invalid]="invalidForm && f[field.key].errors"
                        *ngIf="field.type === 'radio'"
                      >
                        <ng-container *ngFor="let option of field.options">
                          <input
                            *ngIf="field.type === 'radio'"
                            formControlName="{{ field.key }}"
                            class="form-check-input"
                            type="radio"
                            [value]="option.value"
                            [class.is-invalid]="
                              invalidForm && f[field.key].errors
                            "
                          />
                          <h5>{{ option.label }}</h5>

                          <br />
                        </ng-container>
                      </div>

                      <!-- <ng-select
                        *ngIf="field.type === 'checkbox_multi_choice'"
                        formControlName="{{ field.key }}"
                        placeholder="Select {{ field.label }}"
                        required
                        [items]="field.options"
                        bindLabel="label"
                        bindValue="value"
                        [multiple]="true"
                         [class.is-invalid]="invalidForm && f[field.key].errors"
                      >
                      </ng-select> -->
                      <div
                        *ngIf="field.type === 'checkbox_multi_choice'"
                        [class.is-invalid]="invalidForm && f[field.key].errors"
                      >
                        <div
                          class="form-check"
                          [class.is-invalid]="
                            invalidForm && f[field.key].errors
                          "
                          *ngFor="let option of field.options"
                        >
                          <p-checkbox
                            class="mr-1 mb-1"
                            [formControl]="surveyForm.controls[field.key]"
                            [value]="option.value"
                            [class.is-invalid]="
                              invalidForm && f[field.key].errors
                            "
                            (click)="checkboxChange(f[field.key].value)"
                          ></p-checkbox>
                          <label class="form-check-label mb-1">
                            <h5 class="mb-0">{{ option.label }}</h5></label
                          >
                        </div>
                      </div>
                      <div *ngIf="field.type === 'file'">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th>Files to Upload</th>
                              <th>Document</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let option of field.options;
                                let i = index
                              "
                            >
                              <td class="align-middle">
                                <span>{{ option.label }}</span>
                              </td>
                              <td class="text-right">
                                <div *ngIf="formStatus !== 'completed'">
                                  <button
                                    class="btn btn-primary mb-2"
                                    type="button"
                                    [class.is-invalid]="
                                      submitted && invalidApplicationForm
                                    "
                                    (click)="formUpload.click()"
                                  >
                                    Attach File
                                  </button>
                                  <input
                                    type="file"
                                    #formUpload
                                    id="formUpload"
                                    name="formUpload"
                                    style="display: none;"
                                    (change)="
                                      onApplicationFormSelected($event, option)
                                    "
                                  />
                                  <div
                                    *ngIf="
                                      formUpload.files.length &&
                                      !invalidApplicationForm
                                    "
                                    class="mb-2"
                                  >
                                    <strong
                                      >{{ formUpload.files.length }} file
                                      Selected</strong
                                    >
                                  </div>
                                </div>
                                <div *ngIf="option.file">
                                  <a
                                    class="btn btn-link px-0"
                                    (click)="
                                      downloadFile(option.original_filename)
                                    "
                                    >{{ option.file.original_filename }}</a
                                  >
                                  <button
                                    *ngIf="formStatus !== 'completed'"
                                    class="btn btn-icon btn-sm btn-clear text-danger"
                                    (click)="deleteFile(option.file, i, option)"
                                  >
                                    <span class="fa fa-trash"></span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div
                        *ngIf="
                          i &&
                          f[field.key].value &&
                          !(field.type == 'null' || field.type == 'title') &&
                          activeTab !== surveyField.fields.length - 1 &&
                          (f[field.key].value === 'no' || f[field.key].value === 'other'|| suggestion)
                        "
                      >
                        <label
                          ><h6 *ngIf="!field.description">
                            {{ (f[field.key].value === 'no' ||
                            f[field.key].value === 'other') ? "If " +
                            f[field.key].value + "," : "" }} please provide
                            further feedback
                          </h6>
                          <h6 *ngIf="field.description">
                            {{ field.description }}
                          </h6>
                        </label>
                        <br />
                        <textarea
                          class="form-control"
                          rows="10"
                          cols="100"
                          formControlName="comment_{{ i }}"
                        ></textarea>
                      </div>

                      <div
                        *ngIf="invalidForm && f[field.key].errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f[field.key].errors.required">
                          {{ field.label }} is required
                        </div>
                      </div>
                      <br
                        *ngIf="field.type !== 'null' || field.type !== 'title'"
                      />
                    </div>
                  </form>
                </div>
              </ng-template>
            </li>
          </ng-container>
        </ul>
        <div class="d-flex justify-content-between">
          <div>
            <button
              *ngIf="activeTab > 0"
              class="btn btn-size btn-outline-secondary mr-1"
              (click)="prevTutorialStep()"
            >
              Previous Question
            </button>
          </div>
          <div>
            <button
              *ngIf="
                activeTab < surveyField.fields.length &&
                activeTab !== surveyField.fields.length - 1
              "
              class="btn btn-size btn-outline-secondary"
              (click)="nextTutorialStep()"
            >
              Next Question
            </button>
            <button
              *ngIf="activeTab === surveyField.fields.length - 1"
              class="btn btn-size btn-success"
              (click)="submit()"
            >
              Submit
            </button>
            <button
              *ngIf="surveyField.fields.length === 1 && activeTab === 1"
              class="btn btn-size btn-success"
              (click)="submit()"
            >
              Submit
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading && submitted && !surveyForm.invalid">
        <h3><div [innerHTML]="surveyField.end_note"></div></h3>
        <div class="d-flex justify-content-between">
          <div></div>
          <button class="btn btn-size btn-outline-secondary" (click)="close()">
            Close
          </button>
        </div>
      </ng-container>
    </div>
    <div *ngIf="!surveyField && !loading">
      There is no survey for this group
    </div>
    <app-loader *ngIf="loading"></app-loader>
  </div>
</div>
