<div
  *ngIf="message"
  [ngClass]="{
    alert: message,
    'alert-success': message.type === 'success',
    'alert-danger': message.type === 'error'
  }"
>
  {{ message.text }}
</div>
