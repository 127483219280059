import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AcademicYearsService {
  constructor(private http: HttpClient) {}

  //get all
  index() {
    return this.http
      .get<any>(environment.api.url + "/academic-years")
      .toPromise();
  }

  //get one
  single(id: string) {
    return this.http
      .get(environment.api.url + "/academic-years/academic-year/" + id)
      .toPromise();
  }

  //CRUD
  add(model: any) {
    return this.http
      .post(environment.api.url + "/academic-years", model)
      .toPromise();
  }
  edit(id: any, model: any) {
    return this.http
      .post(environment.api.url + "/academic-years/academic-year/" + id, model)
      .toPromise();
  }
  delete(id: string, model: any) {
    return this.http
      .post(
        environment.api.url + "/academic-years/academic-year/" + id + "/delete",
        model
      )
      .toPromise();
  }

  //other functions
  checkAcademicYearExists(data: any) {
    return this.http
      .post(environment.api.url + "/academic-years/check-exists", data)
      .toPromise();
  }

  getCurrentAcademicYear() {
    return this.http
      .get(`${environment.api.url}/academic-years/current`)
      .toPromise();
  }
}
