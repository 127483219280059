import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { DateHelperService } from "../../_helpers/date-helper.service";

@Injectable({
  providedIn: "root",
})
export class DocumentsService {
  constructor(private http: HttpClient, public dateHelper: DateHelperService) {}

  //get all
  index() {
    return this.http.get<any>(environment.api.url + "/documents").toPromise();
  }

  //get one
  single(id: string) {
    return this.http.get(environment.api.url + "/documents/" + id).toPromise();
  }

  //CRUD
  add(model: any) {
    return this.http
      .post(environment.api.url + "/documents/add", model)
      .toPromise();
  }

  addCategory(model: any) {
    return this.http
      .post(environment.api.url + "/documents/add-category", model)
      .toPromise();
  }

  addType(model: any) {
    return this.http
      .post(environment.api.url + "/documents/add-type", model)
      .toPromise();
  }

  edit(id: any, model: any) {
    return this.http
      .post(environment.api.url + "/documents/document/" + id + "/edit", model)
      .toPromise();
  }

  editCategory(id: any, model: any) {
    return this.http
      .post(
        environment.api.url + "/documents/document/" + id + "/edit-category",
        model
      )
      .toPromise();
  }

  editType(id: any, model: any) {
    return this.http
      .post(
        environment.api.url + "/documents/document/" + id + "/edit-type",
        model
      )
      .toPromise();
  }

  delete(id: string, model: any) {
    return this.http
      .post(
        environment.api.url + "/documents/document/" + id + "/delete",
        model
      )
      .toPromise();
  }

  deleteCategory(id: string) {
    return this.http
      .get(environment.api.url + "/documents/delete-category/" + id)
      .toPromise();
  }

  deleteType(id: string) {
    return this.http
      .get(environment.api.url + "/documents/delete-type/" + id)
      .toPromise();
  }

  //other functions
  getUnsignedDocuments(schoolID) {
    return this.http
      .post<any>(environment.api.url + "/documents/unsigned-documents", {
        school: schoolID,
      })
      .toPromise();
  }

  addDocumentsSchools(documents, schoolID) {
    return this.http
      .post(environment.api.url + "/documents/documents-schools", {
        documents: documents,
        school: schoolID,
      })
      .toPromise();
  }

  getSchoolLatestSignedDocuments(schoolID) {
    return this.http
      .post<any>(environment.api.url + "/documents/signed-documents", {
        school: schoolID,
      })
      .toPromise();
  }

  schoolGroupSignedDocumentsBySchool() {
    return this.http
      .get(
        environment.api.url +
          "/documents/school-group-signed-documents-by-school/"
      )
      .toPromise();
  }

  getSignedSchoolsDocumentsBasic(schools) {
    return this.http
      .post(environment.api.url + "/documents/signed-documents/schools/basic", {
        schools: schools,
      })
      .toPromise();
  }

  getGlobalAdminDocuments() {
    return this.http
      .get(environment.api.url + "/documents/document/")
      .toPromise();
  }

  getSchoolGroupDocuments(schoolGroupID) {
    return this.http
      .post<any>(`${environment.api.url}/documents/school-group-documents`, {
        school_group: schoolGroupID,
      })
      .toPromise();
  }

  getLatestSchoolGroupDocuments(schoolGroupIDs, basic = false) {
    return this.http
      .post<any>(
        `${environment.api.url}/documents/school-group-documents/latest`,
        {
          school_groups: schoolGroupIDs,
          basic: basic,
        }
      )
      .toPromise();
  }

  getSingleCategory(categoryID: string) {
    return this.http
      .get(environment.api.url + "/documents/get-category/" + categoryID)
      .toPromise();
  }

  getSingleType(typeID: string) {
    return this.http
      .get(environment.api.url + "/documents/get-type/" + typeID)
      .toPromise();
  }

  getCategories() {
    return this.http
      .get<any>(environment.api.url + "/documents/categories")
      .toPromise();
  }

  getTypes() {
    return this.http
      .get<any>(environment.api.url + "/documents/types")
      .toPromise();
  }

  getLatestParticipationAgreement() {
    return this.http
      .get<any>(
        environment.api.url + "/documents/latest-participation-agreement"
      )
      .toPromise();
  }

  getLatestDocumentVersionIDs(schoolID: string) {
    return this.http
      .get(environment.api.url + "/documents/latest-version-ids/" + schoolID)
      .toPromise();
  }

  getSchoolLatestSignedDocumentsBasic(schoolID) {
    return this.http
      .post<any>(environment.api.url + "/documents/signed-documents/basic", {
        school: schoolID,
      })
      .toPromise();
  }

  public buildDocument(
    document,
    school,
    user,
    secondary: any = false,
    signedDate: any = new Date()
  ) {
    if (document.document.includes("{ school.establishment_name }")) {
      document.document = document.document.replace(
        /{ school.establishment_name }/g,
        school.establishment_name
      );
    }

    if (document.document.includes("{ dsa_image }")) {
      document.document = document.document.replace(
        /{ dsa_image }/g,
        `<img src="/assets/images/dsa-image.png" />`
      );
    }

    // Participation Agreement Primary
    if (
      document.document.includes("{ primary_school.establishment_name }") &&
      secondary
    ) {
      document.document = document.document.replace(
        /{ primary_school.establishment_name }/g,
        school.establishment_name
      );
    }
    // Participation Agreement Secondary
    if (
      document.document.includes("{ secondary_school.establishment_name }") &&
      secondary
    ) {
      document.document = document.document.replace(
        /{ secondary_school.establishment_name }/g,
        secondary.establishment_name
      );
    }

    if (document.document.includes("{ school.street }")) {
      document.document = document.document.replace(
        /{ school.street }/g,
        school.street
      );
    }

    if (document.document.includes("{ school.dfe }")) {
      document.document = document.document.replace(
        /{ school.dfe }/g,
        school.dfe
      );
    }

    // Participation Agreement Primary
    if (document.document.includes("{ primary_school.street }") && secondary) {
      document.document = document.document.replace(
        /{ primary_school.street }/g,
        school.street
      );
    }
    if (document.document.includes("{ primary_school.dfe }") && secondary) {
      document.document = document.document.replace(
        /{ primary_school.dfe }/g,
        school.dfe
      );
    }
    // Participation Agreement Secondary
    if (
      document.document.includes("{ secondary_school.street }") &&
      secondary
    ) {
      document.document = document.document.replace(
        /{ secondary_school.street }/g,
        secondary.street
      );
    }
    if (document.document.includes("{ secondary_school.dfe }") && secondary) {
      document.document = document.document.replace(
        /{ secondary_school.dfe }/g,
        secondary.dfe
      );
    }

    if (document.document.includes("{ school.postcode }")) {
      document.document = document.document.replace(
        /{ school.postcode }/g,
        school.postcode
      );
    }

    // Participation Agreement Primary
    if (
      document.document.includes("{ primary_school.postcode }") &&
      secondary
    ) {
      document.document = document.document.replace(
        /{ primary_school.postcode }/g,
        school.postcode
      );
    }
    // Participation Agreement Secondary
    if (
      document.document.includes("{ secondary_school.postcode }") &&
      secondary
    ) {
      document.document = document.document.replace(
        /{ secondary_school.postcode }/g,
        secondary.postcode
      );
    }

    if (document.document.includes("{ school.town }")) {
      document.document = document.document.replace(
        /{ school.town }/g,
        school.town
      );
    }

    // Participation Agreement Primary
    if (document.document.includes("{ primary_school.town }") && secondary) {
      document.document = document.document.replace(
        /{ primary_school.town }/g,
        school.town
      );
    }
    // Participation Agreement Secondary
    if (document.document.includes("{ secondary_school.town }") && secondary) {
      document.document = document.document.replace(
        /{ secondary_school.town }/g,
        secondary.town
      );
    }

    if (document.document.includes("{ user.email }")) {
      let email = "User no longer in system";
      if (user && user.email) {
        email = user.email[0];
      }
      document.document = document.document.replace(/{ user.email }/g, email);
    }

    if (
      document.document.includes("{ user.forename }") &&
      user &&
      user.forename
    ) {
      let forename = "User no longer in system";
      if (user && user.forename) {
        forename = user.forename;
      }
      document.document = document.document.replace(
        /{ user.forename }/g,
        forename
      );
    }

    if (
      document.document.includes("{ user.surname }") &&
      user &&
      user.surname
    ) {
      let surname = "User no longer in system";
      if (user && user.surname) {
        surname = user.surname;
      }
      document.document = document.document.replace(
        /{ user.surname }/g,
        user.surname
      );
    }

    if (document.document.includes("{ signed_date }")) {
      document.document = document.document.replace(
        /{ signed_date }/g,
        this.dateHelper.formatDate(signedDate)
      );
    }

    return document;
  }
}
