import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../_services/auth.service";
import { UsersService } from "src/app/_services/users/users.service";
import { ErrorService } from "../../_services/global/error.service";
import { SchoolGroupsService } from "src/app/_services/school-groups/school-groups.service";
import { SocialAuthService, GoogleLoginProvider } from "angularx-social-login";
import { MsalService } from "@azure/msal-angular";
import { RegisterPasswordLoginModalComponent } from "src/app/_components/modals/register-password-login-modal/register-password-login-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-register-school",
  templateUrl: "./register-school.component.html",
  styleUrls: ["./register-school.component.css"],
})
export class RegisterSchoolComponent implements OnInit {
  //loading page
  loading = false;

  //group name code from url
  public code: any;
  public invalidCode: any;

  //current group details
  public currentGroup: any;

  //schools dropdown
  public schools: any = null;
  public selectedSchool: any = null;

  //initial details form
  registerForm: FormGroup;
  submittedInitialDetails = false;
  submittedInitialDetailsErrors = false;
  user: any = { school: null, forename: null, surname: null, jobTitle: null };

  //login using google
  errors: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public authService: AuthService,
    public userService: UsersService,
    public schoolGroupService: SchoolGroupsService,
    public errorService: ErrorService,
    private socialAuth: SocialAuthService,
    private msalService: MsalService,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.code = this.route.snapshot.paramMap.get("groupName");
    this.registerForm = this.formBuilder.group({
      acceptTerms: [false, Validators.requiredTrue],
      school: [null, Validators.required],
      forename: [null, Validators.required],
      surname: [null, Validators.required],
      jobTitle: [null],
    });

    await this.getData();
  }

  async getData() {
    this.loading = true;

    if (!this.code) {
      this.invalidCode = true;
      return;
    }

    try {
      //check if the group exists
      let group: any = await this.schoolGroupService.checkSchoolGroupExists(
        this.code
      );
      //if school group exists, then get the info about it
      this.currentGroup = await this.schoolGroupService.single(group._id);

      //filter the schools to only get unregistered ones, and add them to the array
      this.schools = this.currentGroup.schools;
      //add any non member schools to the array to let them register
      for (let nonMemberSchool of this.currentGroup.schools_non_members) {
        this.schools.push(nonMemberSchool);
      }
      //filter the schools to only show the not registered ones
      this.schools = this.schools.filter(
        (school) =>
          !school.registered_date ||
          (school.registered_date && school.registered_date == null)
      );
    } catch (error) {
      let retry;
      if (
        error.status &&
        (error.status === 404 || error.status === 400) &&
        error.error &&
        error.error.message
      ) {
        this.invalidCode = error.error.message;
        return;
      }
      retry = await this.errorService.handleError(error, true);
      if (retry) {
        await this.getData();
      }
    } finally {
      this.loading = false;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  async submitInitialDetails() {
    this.submittedInitialDetails = true;
    this.submittedInitialDetailsErrors = false;

    //if form has errors, then return
    if (this.registerForm.invalid) {
      this.submittedInitialDetailsErrors = true;
      return;
    }

    this.user.school = this.registerForm.value.school;
    this.user.forename = this.registerForm.value.forename;
    this.user.surname = this.registerForm.value.surname;
    this.user.jobTitle = this.registerForm.value.jobTitle;
  }

  async googleLogin() {
    sessionStorage.removeItem("access_token");
    const userData = await this.socialAuth.signIn(
      GoogleLoginProvider.PROVIDER_ID,
      { prompt: "select_account" }
    );
    try {
      //allow the user to login with any google email they choose
      const authToken = await this.authService.registerGoogleLogin(
        userData.idToken,
        this.user
      );
      sessionStorage.setItem("access_token", authToken.access_token);

      //decode auth token and navigate to documents sign page
      await this.authService.registerLogin(authToken);
    } catch (error) {
      console.error(error);
      this.errors = [error.error.message];
      return error;
    }
  }

  async microsoftLogin() {
    await this.msalService.instance.setActiveAccount(null);
    window.localStorage.clear();
    window.sessionStorage.clear();
    const isIE =
      window.navigator.userAgent.indexOf("MSIE ") > -1 ||
      window.navigator.userAgent.indexOf("Trident/") > -1;

    if (isIE) {
      this.msalService.loginRedirect();
    } else {
      let loginResponse: any;
      let x = false;

      var request = {
        scopes: [],
        prompt: "select_account",
      };

      const result = await this.msalService.loginPopup(request).subscribe(
        async (result) => {
          loginResponse = result;
          await this.msalService.instance.setActiveAccount(result.account);
          const msalToken = loginResponse.idToken;

          try {
            const authToken = await this.authService.registerMicrosoftLogin(
              msalToken,
              this.user
            );
            sessionStorage.setItem("access_token", authToken.access_token);
            sessionStorage.setItem("login_type", "Microsoft");
            await this.authService.registerLogin(authToken);
          } catch (error) {
            await this.errorService.handleError(error, false, "Failed Login");
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    }
  }

  async LGFLlogin() {
    //open lgfl login modal
    const modalRef = this.modalService.open(
      RegisterPasswordLoginModalComponent,
      {
        size: "lg",
        windowClass: "animated fadeInDown",
      }
    );

    let value = await modalRef.result;
    this.user.password = value.password;
    this.user.email = value.email;
    const authToken: any = await this.authService.registerPasswordLogin(
      this.user
    );
    sessionStorage.setItem("access_token", authToken.access_token);
    await this.authService.registerLogin(authToken);
  }
}
