import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-document-sign-modal",
  templateUrl: "./document-sign-modal.component.html",
  styleUrls: ["./document-sign-modal.component.css"],
})
export class DocumentSignModalComponent implements OnInit {
  public document: any;

  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.document.document = this.sanitizer.bypassSecurityTrustHtml(
      this.document.document
    );
  }

  submit() {
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.close(false);
  }
}
