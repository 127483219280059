import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import * as moment from "moment";
import { ExcelService } from "../../_services/excel/excel.service";

@Injectable({
  providedIn: "root",
})
export class InvitesService {
  constructor(private http: HttpClient, private excelService: ExcelService) {}

  single(id: string) {
    return this.http
      .get(environment.api.url + "/invites/" + id + "/single")
      .toPromise();
  }

  async bySchool() {
    return this.http
      .get<any>(environment.api.url + "/invites/by-school")
      .toPromise();
  }

  async bySchoolGroup() {
    return this.http
      .get<any>(environment.api.url + "/invites/by-school-group")
      .toPromise();
  }

  sendOnboardingEmail(schoolDetails) {
    return this.http
      .post<any>(
        environment.api.url + "/invites/send-onboarding-email",
        schoolDetails
      )
      .toPromise();
  }

  sendInviteEmail(school) {
    return this.http
      .post<any>(environment.api.url + "/invites/send-invite-email", school)
      .toPromise();
  }

  inviteSchool(schoolID) {
    return this.http
      .post<any>(environment.api.url + "/invites", schoolID)
      .toPromise();
  }

  getInvite(code) {
    const data = {
      code: code,
    };
    return this.http
      .post<any>(`${environment.api.url}/invites/code`, data)
      .toPromise();
  }

  delete(id: string) {
    return this.http
      .post(environment.api.url + "/invites/" + id + "/delete", {})
      .toPromise();
  }

  async download(invites, schoolName) {
    const rows = [];
    rows.push(this.headerRow());
    /**
     * Create header for QLA export
     */
    for (const invite of invites) {
      rows.push(this.row(invite));
    }
    this.excelService.exportCsv(rows, schoolName + " Invites");
  }

  headerRow() {
    let row = [];
    row.push("School URN");
    row.push("School Establishment Name");
    row.push("School Phase of Education");
    row.push("School Registered Date");
    row.push("School Street");
    row.push("School Postcode");
    row.push("Invite Forename");
    row.push("Invite Surname");
    row.push("Invite Email");
    return row;
  }

  row(invite) {
    let row = [];
    row.push(invite.school.urn);
    row.push(invite.school.establishment_name);
    row.push(invite.school.phase_of_education);
    row.push(invite.school.registered_date);
    row.push(invite.school.street);
    row.push(invite.school.postcode);
    row.push(invite.forename);
    row.push(invite.surname);
    row.push(invite.email);
    return row;
  }
}
