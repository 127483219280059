import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SchoolsService {
  constructor(private http: HttpClient) {}

  //get all
  index(query = {}) {
    return this.http
      .get<any>(environment.api.url + "/schools", {
        params: query,
      })
      .toPromise();
  }

  //get one
  single(schoolID) {
    return this.http
      .get(environment.api.url + "/schools/school/" + schoolID)
      .toPromise();
  }

  //CRUD
  add(model: any) {
    return this.http.post(environment.api.url + "/schools", model).toPromise();
  }

  edit(id: any, model: any) {
    return this.http
      .post(environment.api.url + "/schools/school/" + id, model)
      .toPromise();
  }

  delete(id: string, model: any) {
    return this.http
      .post(environment.api.url + "/schools/school/" + id + "/delete", model)
      .toPromise();
  }

  //other functions
  getAllRegisteredSchools() {
    return this.http
      .get<any>(environment.api.url + "/schools/all-registered-schools")
      .toPromise();
  }

  registeredCount() {
    return this.http
      .get<any>(environment.api.url + "/schools/registered/count")
      .toPromise();
  }

  createSchoolVersions() {
    return this.http
      .get<any>(environment.api.url + "/schools/create-school-versions")
      .toPromise();
  }

  registeredCountByPhase(phaseOfEducation) {
    return this.http
      .get<any>(
        `${environment.api.url}/schools/registered/count/${phaseOfEducation}`
      )
      .toPromise();
  }

  searchSchools(data: any, find = {}) {
    data.find = find;
    return this.http
      .post(environment.api.url + "/schools/search-schools", data)
      .toPromise();
  }

  searchSchoolsInGroup(data: any, find = {}) {
    data.find = find;
    return this.http
      .post(environment.api.url + "/schools/search-schools-in-group", data)
      .toPromise();
  }

  getLinkedSchools(schoolID) {
    return this.http
      .get<any>(
        environment.api.url + "/schools/school/" + schoolID + "/linked-schools"
      )
      .toPromise();
  }

  getLinkedSchoolsSimple(schoolID) {
    return this.http
      .get<any>(
        environment.api.url +
          "/schools/school/" +
          schoolID +
          "/linked-schools/simple"
      )
      .toPromise();
  }

  addLinkedSchool(data) {
    return this.http
      .post<any>(
        environment.api.url +
          "/schools/school/" +
          data.requesting_school._id +
          "/linked-schools",
        data
      )
      .toPromise();
  }

  deleteLinkedSchoolEmail(data) {
    return this.http
      .post<any>(
        environment.api.url + "/schools/school/delete-linked-school-email",
        data
      )
      .toPromise();
  }

  sendRegisteredEmail(data) {
    return this.http
      .post<any>(
        environment.api.url +
          "/schools/school/" +
          data.requesting_school._id +
          "/registered-email",
        data
      )
      .toPromise();
  }

  deleteLinkedSchool(schoolID: string, linkedSchoolID: string) {
    return this.http
      .post(
        environment.api.url +
          "/schools/school/" +
          schoolID +
          "/linked-schools/linked-school/" +
          linkedSchoolID +
          "/delete",
        {}
      )
      .toPromise();
  }

  acceptLinkedSchool(
    secondarySchoolName: string,
    schoolID: string,
    linkedSchoolID: string
  ) {
    return this.http
      .post(
        environment.api.url +
          "/schools/school/" +
          schoolID +
          "/linked-schools/linked-school/" +
          linkedSchoolID +
          "/accept",
        {
          secondarySchoolName: secondarySchoolName,
        }
      )
      .toPromise();
  }

  unregisterSchool(schoolID) {
    return this.http
      .get<any>(environment.api.url + "/schools/unregister-school/" + schoolID)
      .toPromise();
  }

  registerSchool(schoolID) {
    return this.http
      .get<any>(environment.api.url + "/schools/register-school/" + schoolID)
      .toPromise();
  }

  updateSchoolData(schools: any) {
    return this.http
      .post(environment.api.url + "/schools/update-school-data", schools)
      .toPromise();
  }

  addLongitudeLatitude(postcodes) {
    return this.http
      .post(environment.api.url + "/schools/add-longitude-latitude", postcodes)
      .toPromise();
  }
}
