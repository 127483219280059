import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class StudentsService {
  constructor(private http: HttpClient) {}

  //get all
  index() {
    return this.http.get<any>(environment.api.url + "/students").toPromise();
  }

  //single
  async single(id: string) {
    const student: any = await this.http
      .get(environment.api.url + "/students/student/" + id)
      .toPromise();
    student.full_name = this.getStudentFullName(student);
    return student;
  }

  //other functions
  async bySchool(query = {}) {
    const students = await this.http
      .get<any>(environment.api.url + "/students/by-school", {
        params: query,
      })
      .toPromise();
    return students.map((student) => {
      student.full_name = this.getStudentFullName(student);
      return student;
    });
  }

  async bySchoolIncMatches(query = {}) {
    const students = await this.http
      .get<any>(environment.api.url + "/students/by-school-including-matches", {
        params: query,
      })
      .toPromise();

    students.map((student) => {
      student.forename = this.getStudentForename(student);

      return student;
    });
    students.map((student) => {
      student.surname = this.getStudentSurname(student);
      return student;
    });

    return students.map((student) => {
      student.full_name = this.getStudentFullName(student);
      return student;
    });
  }

  async bySchoolForAdmissionsUpload(schoolIDs) {
    const students: any = await this.http
      .post<any>(
        environment.api.url + "/students/by-school-for-admissions-upload",
        schoolIDs
      )
      .toPromise();
    return students;
  }

  async bySchoolProfessionalJudgements(query = {}) {
    const students: any = await this.http
      .get<any>(
        environment.api.url + "/students/by-school/professional-judgements",
        {
          params: query,
        }
      )
      .toPromise();
    return students.map((student) => {
      student.full_name = this.getStudentFullName(student);
      return student;
    });
  }

  async bySchoolAttainment() {
    const students: any = await this.http
      .get<any>(environment.api.url + "/students/by-school/attainment")
      .toPromise();
    return students.map((student) => {
      student.full_name = this.getStudentFullName(student);
      return student;
    });
  }

  getStudentSchoolFlagged(model: any) {
    return this.http
      .post(environment.api.url + "/students/get-flagged", model)
      .toPromise();
  }

  getStudentSchoolAppeal(model: any) {
    return this.http
      .post(environment.api.url + "/students/get-appeal", model)
      .toPromise();
  }

  async byLinkedSchools() {
    const schoolsStudents = await this.http
      .get<any>(environment.api.url + "/students/by-linked-schools")
      .toPromise();
    return schoolsStudents.map((school) => {
      school.school_students = school.school_students.map((student) => {
        student.full_name = this.getStudentFullName(student);
        return student;
      });
      return school;
    });
  }

  async byLinkedSchoolsIncFields() {
    const schoolsStudents = await this.http
      .get<any>(environment.api.url + "/students/by-linked-schools-inc-fields")
      .toPromise();
    return schoolsStudents.map((school) => {
      school.school_students = school.school_students.map((student) => {
        student.full_name = this.getStudentFullName(student);
        return student;
      });
      return school;
    });
  }

  async studentsByCurrentSchool() {
    const schoolsStudents = await this.http
      .get<any>(environment.api.url + "/students/by-current-school")
      .toPromise();

    schoolsStudents.map((school) => {
      school.school_students = school.school_students.map((student) => {
        student.full_name = this.getStudentFullName(student);
        return student;
      });
      return school;
    });

    return schoolsStudents;
  }

  countBySchool() {
    return this.http
      .get<any>(environment.api.url + "/students/by-school/count")
      .toPromise();
  }

  groupUploadAdmissionStudents(model: any, counter: string) {
    return this.http
      .post(
        environment.api.url +
          "/students/group-upload-admission-students/" +
          counter,
        model
      )
      .toPromise();
  }

  admissionsUploadEdit(id: string, model: any) {
    return this.http
      .post(
        environment.api.url +
          "/students/student/" +
          id +
          "/admissions-upload-edit",
        model
      )
      .toPromise();
  }

  admissionsUploadDelete(id: string) {
    return this.http
      .get(
        environment.api.url +
          "/students/student/" +
          id +
          "/admissions-upload-delete"
      )
      .toPromise();
  }

  getStudentFieldData(data) {
    return this.http
      .post(`${environment.api.url}/students/student-fields/data`, data)
      .toPromise();
  }

  saveStudentSchoolsFieldData(fields) {
    return this.http
      .post(`${environment.api.url}/students/student-fields/save`, fields)
      .toPromise();
  }

  getStudentFullName(student) {
    let fullName = "";

    let i = 0;
    const showFields = student.fields.filter(
      (field) => field.show_on_list_page && field.name !== "Class"
    );

    for (let showField of showFields) {
      if (i > 0 && i < showFields.length) {
        fullName += ",";
      }
      if (showField.value) {
        if (fullName) {
          fullName += " ";
        }
        fullName += `${showField.value}`;
      }
      fullName = fullName.charAt(0).toUpperCase() + fullName.slice(1);

      i++;
    }
    return fullName;
  }

  getStudentForename(student) {
    let forename = "";
    const showFields = student.fields.filter(
      (field) => field.show_on_list_page
    );
    for (let showField of showFields) {
      if (showField.value && showField.key == "legal_forename") {
        forename = `${showField.value}`;
      }
    }
    return forename;
  }

  getStudentSurname(student) {
    let surname = "";
    const showFields = student.fields.filter(
      (field) => field.show_on_list_page
    );
    for (let showField of showFields) {
      if (showField.value && showField.key == "surname") {
        surname = `${showField.value}`;
      }
    }
    return surname;
  }

  async uploadStudentAttachments(studentSchoolID, data) {
    return this.http
      .post(
        environment.api.url +
          "/students/student/upload-attachments/" +
          studentSchoolID,
        data
      )
      .toPromise();
  }

  async deleteStudentAttachment(studentSchoolAttachmentId) {
    return this.http
      .post(
        environment.api.url +
          "/students/student-attachment/" +
          studentSchoolAttachmentId +
          "/delete",
        null
      )
      .toPromise();
  }

  senStats(body) {
    return this.http
      .post(environment.api.url + "/students/sen-stats", {
        studentSchoolIDs: body[0],
        studentFieldID: body[1],
        fieldValues: body[2],
      })
      .toPromise();
  }

  senStudents(body) {
    return this.http
      .post(environment.api.url + "/students/sen-students", {
        studentSchoolIDs: body[0],
        studentFieldID: body[1],
        fieldValues: body[2],
      })
      .toPromise();
  }

  updateFlagContactDetails(student) {
    return this.http
      .post(
        environment.api.url + "/students/update-flag-contact-details",
        student
      )
      .toPromise();
  }

  async getBySchoolGroup(schoolGroupID) {
    return this.http
      .get<any>(environment.api.url + "/students/school-group/" + schoolGroupID)
      .toPromise();
  }

  async countBySchoolGroup(schoolGroupID) {
    return this.http
      .get<any>(
        environment.api.url + "/students/school-group-count/" + schoolGroupID
      )
      .toPromise();
  }

  async addAppeal(studentSchoolID) {
    return this.http
      .get<any>(environment.api.url + "/students/add-appeal/" + studentSchoolID)
      .toPromise();
  }

  async deleteAppeal(student) {
    let body = {
      student_school: student.student_school,
      forename: student.forename,
      surname: student.surname,
    };

    return this.http
      .post(environment.api.url + "/students/delete-appeal", body)
      .toPromise();
  }

  async openFlag(studentSchoolID) {
    return this.http
      .get<any>(environment.api.url + "/students/open-flag/" + studentSchoolID)
      .toPromise();
  }

  async closeFlag(studentSchoolID) {
    return this.http
      .get<any>(environment.api.url + "/students/close-flag/" + studentSchoolID)
      .toPromise();
  }

  async clearSendDataForStudents(schoolStudents) {
    return this.http
      .post(
        environment.api.url + "/students/clear-send-data-for-students",
        schoolStudents
      )
      .toPromise();
  }

  //Temporary. Used to clear all send data for students that have N set as their latest send_status
  async clearAllSendData() {
    return this.http
      .post(environment.api.url + "/students/clear-all-send-data", null)
      .toPromise();
  }
}
