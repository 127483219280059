import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbNav } from "@ng-bootstrap/ng-bootstrap";
import { DateHelperService } from "../../../_helpers/date-helper.service";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { DynamicFormsService } from "src/app/_services/dynamic-forms/dynamic-forms.service";

@Component({
  selector: "app-survey-modal",
  templateUrl: "./survey-modal.component.html",
  styleUrls: ["./survey-modal.component.css"],
})
export class SurveyModalComponent implements OnInit {
  @ViewChild("tutorialNav", { static: false })
  tutorialNav: NgbNav;

  loading = true;
  currentUser: any;

  perPage = 5;
  currentPage = 1;
  totalItems = 0;
  sortDesc = false;
  searchKeys = ["id", "name"];
  filterVal = "";
  sortBy = "name";
  previousUrl: string;
  globalAdmin: boolean;
  hasSchoolGroup = true;
  surveyForm: FormGroup;
  surveyField: any;
  items: any;
  institutionID: string;
  groupID: string;
  activeTab: number;
  submitted = false;
  proceed: boolean = false;
  preview;
  previewData;
  invalidForm = false;
  suggestion: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public dateHelper: DateHelperService,
    private formBuilder: FormBuilder,
    private dynamicFormService: DynamicFormsService
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.groupID = sessionStorage.getItem("groupID");
    await this.getData();
    this.loading = false;
  }
  async getData() {
    this.activeTab = 0;
    let survey = this.previewData;
    const fields = this.preview
      ? this.previewData.fields.map((field) => {
          field.type = field.type._id;
          return field;
        })
      : [];

    survey = { ...this.previewData, fields: fields };
    this.surveyField = this.preview
      ? survey
      : await this.dynamicFormService.getSurveyForm({
          phase_of_education: this.currentUser.school.phase_of_education,
          type: "survey",
        });

    if (this.surveyField) {
      this.surveyForm = this.formBuilder.group({});
      for (let [index, field] of this.surveyField.fields.entries()) {
        if (field.optional) {
          this.surveyForm.addControl(
            field.key,
            new FormControl({ value: null, disabled: false })
          );
        } else {
          this.surveyForm.addControl(
            field.key,
            new FormControl(
              { value: null, disabled: false },
              Validators.compose([Validators.required])
            )
          );
        }
        this.surveyForm.addControl(
          `comment_${index}`,
          new FormControl({
            value: null,
            disabled: false,
          })
        );
      }
    }
  }

  checkboxChange(value) {
    if (value.includes("other")) {
      this.suggestion = true;
    } else {
      this.suggestion = false;
    }
  }

  get f() {
    return this.surveyForm.controls;
  }

  proceedWithSurvey() {
    this.proceed = true;
  }

  prevTutorialStep() {
    this.tutorialNav.select(this.activeTab - 1);
  }

  nextTutorialStep() {
    this.tutorialNav.select(this.activeTab + 1);
  }

  async submit() {
    this.loading = true;
    this.submitted = true;
    try {
      let fields = [];

      if (this.surveyForm.invalid) {
        this.submitted = false;
        this.invalidForm = true;
        this.loading = false;
        return;
      }
      for (let [index, field] of this.surveyField.fields.entries()) {
        let pushObject = {
          form_field: field._id,
          value: null,
          comment: null,
          user: this.currentUser._id,
          form: this.surveyField._id,
        };

        if (
          field.type === "text" ||
          field.type === "textarea" ||
          field.type === "dropdown" ||
          field.type === "radio" ||
          field.type === "checkbox_multi_choice"
        ) {
          pushObject.value = this.currentUser.school
            ? this.surveyForm.value[field.key]
            : this.surveyForm.controls[field.key].value;
          if (this.surveyForm.value.hasOwnProperty("comment_" + index)) {
            pushObject.comment = this.surveyForm.value["comment_" + index];
          }
        } else if (field.type === "date") {
          pushObject.value = this.dateHelper.objectToDate(
            this.currentUser.school
              ? this.surveyForm.value[field.key]
              : this.surveyForm.controls[field.key].value
          );
        }
        fields.push(pushObject);
      }
      if (!this.preview) {
        await this.dynamicFormService.addFormFields(fields);
        this.currentUser.survey_complete = true;
        sessionStorage.setItem("currentUser", JSON.stringify(this.currentUser));
      }
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  close() {
    this.activeModal.close(false);
  }
}
