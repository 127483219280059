<div class="app-content content login-page">
  <div class="content-wrapper">
    <div class="content-body">
      <section class="flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-md-6 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
              <div class="card-header border-0 pb-0">
                <div class="card-title text-center">
                  <img
                    class="login-logo"
                    src="assets/images/logo/logo.svg"
                    alt="branding logo"
                  />
                </div>
                <h6
                  class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2 mb-0"
                >
                  <span
                    *ngIf="this.submittedInitialDetails == false || submittedInitialDetailsErrors == true"
                    >Register Your School</span
                  >
                  <span
                    *ngIf="this.submittedInitialDetails == true && submittedInitialDetailsErrors == false"
                    >Sign In</span
                  >
                </h6>
              </div>
              <div class="card-content" *ngIf="!loading">
                <div class="card-body">
                  <!-- display initial details form to start with (if not submitted yet, or there are errors) -->
                  <ng-container
                    *ngIf="this.submittedInitialDetails == false || submittedInitialDetailsErrors == true"
                  >
                    <form class="form-horizontal" [formGroup]="registerForm">
                      <fieldset>
                        <div class="form-group form-check">
                          <input
                            type="checkbox"
                            formControlName="acceptTerms"
                            id="acceptTerms"
                            class="form-check-input"
                            [class.is-invalid]="submittedInitialDetails && f.acceptTerms.errors"
                          />

                          <label for="acceptTerms" class="form-check-label"
                            ><strong>Disclaimer</strong> I confirm that I have
                            the authority to accept agreements on behalf of my
                            school.</label
                          >
                          <div
                            *ngIf="submittedInitialDetails && f.acceptTerms.errors"
                            class="invalid-feedback"
                          >
                            Accept Disclaimer required
                          </div>
                        </div>
                      </fieldset>

                      <fieldset
                        class="form-group position-relative has-icon-left"
                      >
                        <ng-select
                          bindLabel="establishment_name"
                          bindValue="_id"
                          formControlName="school"
                          placeholder="Select School"
                          required
                          [items]="schools"
                          [clearable]="false"
                          [class.is-invalid]="submittedInitialDetails && f.school.errors"
                        >
                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index"
                          >
                            <strong>{{ item.establishment_name }}</strong>
                            <br />
                            <small>
                              {{ item.street }}, {{ item.postcode }}</small
                            >
                          </ng-template>
                        </ng-select>

                        <div
                          *ngIf="submittedInitialDetails && f.school.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.school.errors.required">
                            School is required
                          </div>
                        </div>
                      </fieldset>

                      <fieldset
                        class="form-group position-relative has-icon-left"
                      >
                        <input
                          type="text"
                          id="forename"
                          formControlName="forename"
                          class="form-control"
                          [ngClass]="{
                            'is-invalid': submittedInitialDetails && f.forename.errors
                          }"
                          placeholder="Forename"
                        />
                        <div class="form-control-position">
                          <i class="feather ft-user"></i>
                        </div>
                        <div
                          *ngIf="submittedInitialDetails && f.forename.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.forename.errors.required">
                            Forename is required
                          </div>
                        </div>
                      </fieldset>

                      <fieldset
                        class="form-group position-relative has-icon-left"
                      >
                        <input
                          type="text"
                          formControlName="surname"
                          class="form-control"
                          [ngClass]="{
                            'is-invalid': submittedInitialDetails && f.surname.errors
                          }"
                          placeholder="Surname"
                        />
                        <div class="form-control-position">
                          <i class="feather ft-user"></i>
                        </div>
                        <div
                          *ngIf="submittedInitialDetails && f.surname.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.surname.errors.required">
                            Surname is required
                          </div>
                        </div>
                      </fieldset>

                      <fieldset
                        class="form-group position-relative has-icon-left"
                      >
                        <input
                          type="text"
                          formControlName="jobTitle"
                          class="form-control"
                          [ngClass]="{
                            'is-invalid': submittedInitialDetails && f.jobTitle.errors
                          }"
                          placeholder="Job Title (optional)"
                        />
                        <div class="form-control-position">
                          <i class="feather ft-clipboard"></i>
                        </div>
                        <div
                          *ngIf="submittedInitialDetails && f.jobTitle.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.jobTitle.errors.required">
                            Job Title is required
                          </div>
                        </div>
                      </fieldset>

                      <div class="justify-content-enter">
                        <button
                          (click)="submitInitialDetails()"
                          type="submit"
                          class="btn btn-size btn-success col-4 mb-1"
                        >
                          Continue
                        </button>
                      </div>
                    </form>
                  </ng-container>

                  <!-- Sign in with school email credentials to confirm details -->
                  <ng-container
                    *ngIf="this.submittedInitialDetails == true && submittedInitialDetailsErrors == false"
                  >
                    <div class="text-center">
                      Sign in with you school email to confirm your details.
                      <br />
                      You can sign in with Google, Microsoft or Other, depending
                      on your email account
                      <br />

                      <button
                        type="button"
                        class="btn btn-clear google-login"
                        (click)="googleLogin()"
                      >
                        <img
                          src="assets/media/logos/btn_google_signin_light_normal_web@2x.png"
                          alt="Sign in with Google"
                          class="google-logo"
                        />
                      </button>
                      <br />
                      <button class="btn" (click)="microsoftLogin()">
                        <img
                          src="assets/media/logos/ms-symbollockup_signin_light.svg"
                          alt="Sign in with Microsoft"
                        />
                      </button>
                      <br />
                      <button class="btn" (click)="LGFLlogin()">
                        <img
                          src="assets/media/logos/other-login.png"
                          alt="Sign in with Other"
                          style="width: 220px;"
                        />
                      </button>
                    </div>
                  </ng-container>
                </div>
              </div>
              <app-loader *ngIf="loading"></app-loader>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
