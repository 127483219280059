<div class="modal-body">
  <div class="card-header border-0 pb-0">
    <div class="card-title text-center">
      <img
        class="login-logo"
        src="assets/images/logo/logo.svg"
        alt="branding logo"
      />
    </div>
    <h6
      class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"
    >
      <span>Enter your email address</span>
    </h6>
  </div>

  <div class="card-content">
    <ng-container>
      <div class="card-body">
        <form class="form-horizontal" [formGroup]="loginForm">
          <fieldset class="form-group">
            <input
              type="text"
              formControlName="email"
              class="form-control"
              [ngClass]="{
                    'is-invalid': submitted && f.email.errors
                  }"
              placeholder="Please enter your email"
            />
          </fieldset>

          <ng-container *ngIf="this.EmailNotExist == true"
            ><p style="color: red;">
              Email Not Found - this should match the email address SixIntoSeven
              sent for you to register
            </p></ng-container
          >
          <button
            type="button"
            class="btn btn-size btn-outline-secondary"
            (click)="checkEmail()"
          >
            Click to Confirm
          </button>

          <br />
          <br />

          <fieldset
            class="form-group position-relative"
            *ngIf="newPasswordNeeded"
          >
            <h6>
              Please enter a new password for your SixintoSeven account. The
              password must be a minimum of eight characters in length and
              contain:
              <ul>
                <li>At least one upper case letter</li>
                <li>At least one lower case letter</li>
                <li>At least one digit</li>
                <li>At least one special character</li>
              </ul>
            </h6>
            <fieldset class="form-group position-relative has-icon-left">
              <div class="row">
                <div class="col pl-0 pr-0">
                  <input
                    [type]="viewPassword ? 'text' : 'password'"
                    placeholder="Enter a new password"
                    formControlName="password"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && f.password.errors
                    }"
                  />
                </div>
                <div class="col col-auto pl-0 pr-0">
                  <i class="btn btn-light" (click)="toggleViewPassword()">
                    <i class="fa fa-eye-slash" *ngIf="viewPassword"></i>
                    <i class="fa fa-eye" *ngIf="!viewPassword"></i>
                  </i>
                </div>
              </div>
            </fieldset>

            <fieldset class="form-group position-relative has-icon-left">
              <div class="row">
                <div class="col pl-0 pr-0">
                  <input
                    [type]="viewConfirmPassword ? 'text' : 'password'"
                    placeholder="Confirm password"
                    formControlName="confirm_password"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && f.confirm_password.errors
                    }"
                  />
                </div>
                <div class="col col-auto pl-0 pr-0">
                  <i
                    class="btn btn-light"
                    (click)="toggleViewConfirmPassword()"
                  >
                    <i class="fa fa-eye-slash" *ngIf="viewConfirmPassword"></i>
                    <i class="fa fa-eye" *ngIf="!viewConfirmPassword"></i>
                  </i>
                </div>
              </div>
            </fieldset>

            <ng-container *ngIf="this.passwordNotMatch == true"
              ><p style="color: red;">Passwords Don't Match</p></ng-container
            >
            <ng-container *ngIf="this.passwordRegexNotMet == true"
              ><p style="color: red;">
                Passwords must contain a minimum of eight characters and at
                least one upper case letter, one lower case letter, one digit
                and one special character
              </p></ng-container
            >
          </fieldset>

          <fieldset
            class="form-group position-relative"
            *ngIf="newPasswordNotNeeded"
          >
            <fieldset class="form-group position-relative has-icon-left">
              <div class="row">
                <div class="col pl-1 pr-0">
                  <input
                    [type]="viewPassword ? 'text' : 'password'"
                    placeholder="Enter your password"
                    formControlName="password"
                    class="form-control"
                    [ngClass]="{
                    'is-invalid': submitted && f.password.errors
                  }"
                  />
                </div>
                <div class="col col-auto pl-0 pr-1">
                  <i class="btn btn-light" (click)="toggleViewPassword()">
                    <i class="fa fa-eye-slash" *ngIf="viewPassword"></i>
                    <i class="fa fa-eye" *ngIf="!viewPassword"></i>
                  </i>
                </div>
              </div>
              <ng-container *ngIf="this.wrongPassword == true"
                ><p style="color: red;">Incorrect Password</p></ng-container
              >
            </fieldset>

            <button
              type="button"
              class="btn btn-size btn-outline-secondary"
              (click)="forgotPassword()"
            >
              Forgot Password?
            </button>

            <div
              *ngIf="sentLoginEmail == true"
              class="bs-callout-success callout-border-left p-1"
            >
              Password reset request has been sent, if a matching email address
              is found on SixIntoSeven you will recieve an email allowing you to
              reset your password. Password resets are valid for 24 hours.
            </div>
          </fieldset>
        </form>
      </div>
    </ng-container>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-size grey btn-success"
    (click)="login()"
    [disabled]="!emailEntered"
  >
    Login
  </button>
  <button
    type="button"
    class="btn btn-size btn-outline-secondary mr-1 ml-1"
    (click)="close()"
  >
    Cancel
  </button>
</div>
