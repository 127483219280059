import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { DateHelperService } from "../../_helpers/date-helper.service";

@Injectable({
  providedIn: "root",
})
export class AlertsService {
  constructor(private http: HttpClient, public dateHelper: DateHelperService) {}

  //get all
  index() {
    return this.http.get<any>(environment.api.url + "/alerts").toPromise();
  }

  //get one
  single(id: any) {
    return this.http
      .get<any>(environment.api.url + "/alerts/alert/" + id)
      .toPromise();
  }

  //CRUD
  add(alert: any) {
    return this.http
      .post(environment.api.url + "/alerts/add", alert)
      .toPromise();
  }

  edit(id: any, alert: any) {
    return this.http
      .post(environment.api.url + "/alerts/alert/" + id + "/edit", alert)
      .toPromise();
  }

  delete(id: string) {
    return this.http
      .get(environment.api.url + "/alerts/delete-alert/" + id)
      .toPromise();
  }

  //other functions
  getOneForCurrentSchool(schoolID) {
    return this.http
      .get(environment.api.url + "/alerts/alert/one-current-school/" + schoolID)
      .toPromise();
  }

  getOneForCurrentGroup(schoolGroupID) {
    return this.http
      .get(
        environment.api.url + "/alerts/alert/one-current-group/" + schoolGroupID
      )
      .toPromise();
  }

  getBySchoolGroup(schoolGroupID) {
    return this.http
      .get(environment.api.url + "/alerts/alert/school-group/" + schoolGroupID)
      .toPromise();
  }
}
