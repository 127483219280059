import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SupportService {
  constructor(private http: HttpClient) {}

  sendSupportEmail(emailData: any) {
    return this.http
      .post(environment.api.url + "/support", emailData)
      .toPromise();
  }
}
