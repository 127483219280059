<!-- main app container -->
<!-- <ngx-loading-bar
  [includeSpinner]="false"
  height="3px"
  color="#FF4961"
></ngx-loading-bar> -->
<app-alert></app-alert>
<router-outlet *ngIf="showContent && !IE"></router-outlet>

<div *ngIf="IE" class="unsupported">
  <div class="container">
    <div class="container-wrapper">
      <div class="container-full-column">
        <div class="unsupported-browser">
          <h1>Please upgrade your browser in order to use SixIntoSeven</h1>
          <p>
            We built SixIntoSeven using the latest technology which improves the
            look of the site, increases the speed of the site, and gives you a
            better experience with new features and functions. Unfortunately,
            your browser does not support these technologies.
          </p>
          <h2>Please download one of these free and up-to-date browsers:</h2>
          <ul>
            <li>
              <a href="https://www.mozilla.com/firefox/" target="_blank"
                >Firefox</a
              >
            </li>
            <li>
              <a
                href="https://www.google.com/chrome/browser/desktop/"
                target="_blank"
                >Chrome</a
              >
            </li>
            <li>
              <a
                href="https://www.microsoft.com/en-us/windows/microsoft-edge"
                target="_blank"
                >Microsoft Edge</a
              >
            </li>
          </ul>
          <hr />
          <div class="unsupported-message">
            <h3>I can't update my browser</h3>
            <ul>
              <li>
                Ask your system administrator to update your browser if you
                cannot install updates yourself.
              </li>
              <li>
                If you can't change your browser because of compatibility
                issues, think about installing a second browser for utilization
                of this site and keep the old one for compatibility.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
