import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { DateHelperService } from "../../_helpers/date-helper.service";

@Injectable({
  providedIn: "root",
})
export class SchoolGroupsService {
  constructor(private http: HttpClient, public dateHelper: DateHelperService) {}

  //get all
  index() {
    return this.http
      .get<any>(environment.api.url + "/school-groups")
      .toPromise();
  }

  //get one
  single(id: string, query = {}) {
    return this.http
      .get(environment.api.url + "/school-groups/school-group/" + id, {
        params: query,
      })
      .toPromise();
  }

  //CRUD
  add(model: any) {
    return this.http
      .post(environment.api.url + "/school-groups", model)
      .toPromise();
  }

  edit(id: any, model: any) {
    return this.http
      .post(environment.api.url + "/school-groups/school-group/" + id, model)
      .toPromise();
  }

  delete(id: string, model: any) {
    return this.http
      .post(
        environment.api.url + "/school-groups/school-group/" + id + "/delete",
        model
      )
      .toPromise();
  }

  removeSchool(schoolGroupID, schoolID) {
    return this.http
      .get(
        environment.api.url +
          "/school-groups/school-group/remove-school/" +
          schoolGroupID +
          "/" +
          schoolID
      )
      .toPromise();
  }

  removeMultipleSchools(schools) {
    return this.http
      .post(
        environment.api.url +
          "/school-groups/school-group/remove-multiple-schools",
        schools
      )
      .toPromise();
  }

  //other functions
  getLaCodes() {
    return this.http
      .get<any>(environment.api.url + "/school-groups/get-la-codes")
      .toPromise();
  }

  bySchool(id: string) {
    return this.http
      .get(environment.api.url + "/school-groups/school/" + id)
      .toPromise();
  }

  lookupLaName(id: string) {
    return this.http
      .get(environment.api.url + "/school-groups/lookup-la-name/" + id)
      .toPromise();
  }

  bySchools(schoolIDs) {
    return this.http
      .post(environment.api.url + "/school-groups/by/schools", {
        schools: schoolIDs,
      })
      .toPromise();
  }

  checkSchoolGroupExists(code: any) {
    return this.http
      .post(environment.api.url + "/school-groups/check-school-group-exists", {
        code: code,
      })
      .toPromise();
  }

  async uploadGroupLogo(schoolGroupID, data) {
    return this.http
      .post(
        environment.api.url + "/school-groups/upload-logo/" + schoolGroupID,
        data
      )
      .toPromise();
  }

  getSchoolGroupLogo(schoolGroupID) {
    return this.http
      .get(
        environment.api.url +
          "/school-groups/get-school-group-logo/" +
          schoolGroupID
      )
      .toPromise();
  }

  uploadLaCodes(codes: any) {
    return this.http
      .post(environment.api.url + "/school-groups/upload-la-codes", codes)
      .toPromise();
  }

  //get map data for transition summary reports
  async dataMapping(schoolGroupID) {
    return this.http
      .get<any>(
        environment.api.url + "/school-groups/data-mapping/" + schoolGroupID
      )
      .toPromise();
  }
  async schoolDataMapping(schoolID) {
    return this.http
      .get<any>(
        environment.api.url + "/school-groups/school-data-mapping/" + schoolID
      )
      .toPromise();
  }
}
