import {
  Component,
  OnInit,
  Inject,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewInit,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NavbarService } from "../../../_services/navbar.service";
import { ThemeSettingsService } from "../../settings/theme-settings.service";
import { MenuSettingsService } from "../../settings/menu-settings.service";
import { takeUntil } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AuthService } from "../../../_services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
  PerfectScrollbarDirective,
} from "ngx-perfect-scrollbar";
import { AppConstants } from "../../../_helpers/app.constants";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AcademicYearSelectorModalComponent } from "../../../_components/modals/academic-year-selector-modal/academic-year-selector-modal.component";
import { ErrorService } from "../../../_services/global/error.service";
import { DateHelperService } from "../../../_helpers/date-helper.service";
import {
  faCity,
  faUniversity,
  faUser,
  faCrown,
  faChartBar,
  faQuestionCircle,
  faUpload,
  faBell,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
} from "@fortawesome/pro-light-svg-icons";
import { SupportRequestModalComponent } from "src/app/_components/modals/support-request-modal/support-request-modal.component";
import { ToastrService } from "ngx-toastr";
import { StudentsService } from "src/app/_services/students/students.service";
import { DocumentsService } from "src/app/_services/documents/documents.service";
import { SchoolGroupsService } from "src/app/_services/school-groups/school-groups.service";
import { SchoolsService } from "src/app/_services/schools/schools.service";
import { Location } from "@angular/common";
import { AuthGuard } from "../../../_guards/auth.guard";
import { AlertsService } from "src/app/_services/alerts/alerts.service";
import { threadId } from "worker_threads";
import { NotificationsService } from "src/app/_services/notifications/notifications.service";
import { UsersService } from "src/app/_services/users/users.service";
import { SurveyModalComponent } from "../../../_components/modals/survey-modal/survey-modal.component";
import { DynamicFormsService } from "src/app/_services/dynamic-forms/dynamic-forms.service";

const AWS = require("aws-sdk");
AWS.config.update({
  region: "eu-west-2",
  apiVersion: "latest",
  credentials: {
    accessKeyId: "AKIA2WVYLYMC73ZNL6MH",
    secretAccessKey: "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",
  },
});
const s3 = new AWS.S3();

let params = {
  Bucket: "sixintoseven-school-logos",
  Key: "filename",
};

const docElmWithBrowsersFullScreenFunctions =
  document.documentElement as HTMLElement & {
    mozRequestFullScreen(): Promise<void>;
    webkitRequestFullscreen(): Promise<void>;
    msRequestFullscreen(): Promise<void>;
  };

const docWithBrowsersExitFunctions = document as Document & {
  mozCancelFullScreen(): Promise<void>;
  webkitExitFullscreen(): Promise<void>;
  msExitFullscreen(): Promise<void>;
};
@Component({
  selector: "app-header-vertical",
  templateUrl: "./vertical.component.html",
  styleUrls: ["./vertical.component.css"],
})
export class VerticalComponent
  extends AuthGuard
  implements OnInit, AfterViewInit
{
  faExclamationTriangle = faExclamationTriangle;
  notifications: any = [];
  unreadNotifications: any = [];

  alerts: any = [];

  totalPupilFlags = 0;
  totalPendingLinkedSchools = 0;
  faCity = faCity;
  faUniversity = faUniversity;
  faUser = faUser;
  faCrown = faCrown;
  faChartBar = faChartBar;
  faQuestionCircle = faQuestionCircle;
  faUpload = faUpload;
  faBell = faBell;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  notificationNumber = 0;

  insideTm: any;
  outsideTm: any;
  private _unsubscribeAll: Subject<any>;
  private _unsubscribeAllMenu: Subject<any>;
  public _themeSettingsConfig: any;
  private _menuSettingsConfig: any;
  public selectedHeaderNavBarClass: string;
  public selectedNavBarHeaderClass: string;
  public currentUser: any;
  public isHeaderSearchOpen: any;
  isMobile = false;
  showNavbar = false;
  public maximize: any;
  public search: any;
  public internationalization: any;
  public notification: any;
  public email: any;
  urlPrefix: any = "school-group";
  public config: PerfectScrollbarConfigInterface = { wheelPropagation: false };
  @ViewChild(PerfectScrollbarComponent)
  componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true })
  directiveRef?: PerfectScrollbarDirective;
  public version: string = "";
  public phase: string = "Global Admin";
  public groupDashboard;

  public academicYear = null;
  linkedSchoolGroup: any = [];
  public backLink: any = null;
  public backLinkText: String = "Back";
  public nextLink: any = null;
  public nextLinkText: String = "Next";
  invites: any = [];
  backLinkNew$: Observable<any>;
  backLinkNewSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  alert: any;
  logo: any;
  survey;
  constructor(
    @Inject(DOCUMENT) public document: Document,
    private _renderer: Renderer2,
    private navbarService: NavbarService,
    private _themeSettingsService: ThemeSettingsService,
    private _menuSettingsService: MenuSettingsService,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private modalService: NgbModal,
    private errorService: ErrorService,
    public dateHelper: DateHelperService,
    private toastr: ToastrService,
    private studentsService: StudentsService,
    private documentsService: DocumentsService,
    private schoolGroupsService: SchoolGroupsService,
    private schoolsService: SchoolsService,
    public cd: ChangeDetectorRef,
    private location: Location,
    private alertsService: AlertsService,
    private notificationsService: NotificationsService,
    private userService: UsersService,
    private dynamicFormService: DynamicFormsService
  ) {
    super(router, authService);
    this._unsubscribeAll = new Subject();
    this._unsubscribeAllMenu = new Subject();
    // this.backLink = this.authService.backLinkSubject.next(
    //   sessionStorage.getItem("previousUrl")
    // );
  }

  logout() {
    if (sessionStorage.getItem("currentUser")) {
      this.authService.doLogout().then(
        (res) => {
          // window.location.href = "/login";
          res;
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }

  async ngOnInit() {
    this.groupDashboard = JSON.parse(sessionStorage.getItem("groupDashboard"));
    this.backLink = this.authService.backLinkSubject.value;
    this.isMobile = window.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH;
    if (!this.isMobile) {
      this.showNavbar = true;
    }
    if (sessionStorage.getItem("currentUser")) {
      this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
      if (this.currentUser.school) {
        this.linkedSchoolGroup = await this.schoolGroupsService.bySchool(
          this.currentUser.school._id
        );

        //if the school is not part of a school group (must be a non member of one)
        //then get their real school group name
        if (this.linkedSchoolGroup == null) {
          this.linkedSchoolGroup = await this.schoolGroupsService.lookupLaName(
            this.currentUser.school._id
          );
        }

        // params.Key = this.currentUser.school._id + ".png";
        // try {
        //   await s3.headObject(params).promise();
        //   this.logo =
        //     "https://sixintoseven-school-logos.s3.eu-west-2.amazonaws.com/" +
        //     this.currentUser.school._id +
        //     ".png";
        // } catch (err) {}

        this.urlPrefix = "school";
      }
    }

    // Subscribe to config changes
    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._themeSettingsConfig = config;
        this.refreshView();
      });
    this._menuSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._menuSettingsConfig = config;
      });
    this.maximize = this._themeSettingsConfig.headerIcons.maximize;
    this.search = this._themeSettingsConfig.headerIcons.search;
    this.internationalization =
      this._themeSettingsConfig.headerIcons.internationalization;
    this.notification = this._themeSettingsConfig.headerIcons.notification;
    this.email = this._themeSettingsConfig.headerIcons.email;
    if (
      this.currentUser &&
      this.currentUser.global_admin &&
      !this.currentUser.school &&
      !this.currentUser.school_group
    ) {
      this.version = null;
    }

    if (this.currentUser && this.currentUser.academic_year) {
      this.academicYear = this.currentUser.academic_year;
    }

    if (
      this.currentUser &&
      this.currentUser.school_group &&
      this.currentUser.school_group.name
    ) {
      this.phase = `School Group`;
      this.version = this.currentUser.school_group.name;
    }

    if (
      this.currentUser &&
      this.currentUser.school &&
      this.currentUser.school.phase_of_education
    ) {
      this.phase = `${this.currentUser.school.phase_of_education}`;
      this.version = null;
    }
    this.getData();
    this.cd.detectChanges();
  }

  async getData() {
    //Get the priority alert to show across the top
    if (this.currentUser.school_group) {
      this.alert = await this.alertsService.getOneForCurrentGroup(
        this.currentUser.school_group._id
      );
    }
    if (this.currentUser.school) {
      this.alert = await this.alertsService.getOneForCurrentSchool(
        this.currentUser.school._id
      );
      //Get all alerts (set at group level or global admin level) and notifications from db tables to show in notifications bell

      this.notifications = await this.notificationsService.getBySchool(
        this.currentUser.school._id
      );

      //Get the survey form for the user
      this.survey = await this.dynamicFormService.getSurveyForm({
        phase_of_education: this.currentUser.school.phase_of_education,
        type: "survey",
      });
      if (this.survey) {
        this.survey = {
          button: "/school/pupils",
          buttonText: "Open Survey",
          date: new Date().toISOString(),
          description: "Please complete the survey",
          name: "SixIntoSeven Survey",
          read: false,
          school: this.currentUser.school,
          type: "survey",
          _id: "01",
        };
        this.unreadNotifications.push(this.survey);
      }
    }

    //look up in the notifications to see whether a notification for this alert was sent today
    if (this.alert) {
      let pastAlertNotifications = this.notifications.filter(
        (notification) =>
          notification.alert != null &&
          notification.alert.toString() == this.alert._id &&
          this.isSameDay(notification.date) == true
      );
      //if no notification is found for today, then create one
      if (pastAlertNotifications.length == 0) {
        let newNotification;
        if (this.currentUser.school_group) {
          newNotification = {
            name: this.alert.name,
            read: false,
            alert: this.alert._id,
            date: new Date(),
            type: "Alert",
            description: this.alert.group_message,
            button: null,
            buttonText: null,
            school: null,
            school_group: this.currentUser.school_group._id,
          };
        }
        if (
          this.currentUser.school &&
          this.currentUser.school.phase_of_education == "Primary"
        ) {
          newNotification = {
            name: this.alert.name,
            read: false,
            alert: this.alert._id,
            date: new Date(),
            description: this.alert.primary_message,
            button: null,
            school_group: null,
            school: this.currentUser.school,
          };
        }
        if (
          this.currentUser.school &&
          this.currentUser.school.phase_of_education == "Secondary"
        ) {
          newNotification = {
            name: this.alert.name,
            read: false,
            alert: this.alert._id,
            date: new Date(),
            description: this.alert.secondary_message,
            button: null,
            school_group: null,
            school: this.currentUser.school,
          };
        }
        //save the new notification in the table
        let notificationSaved = await this.notificationsService.addNotification(
          newNotification
        );
        this.notifications.push(notificationSaved);
      }
    }
    //sort the notifications with newest at the top
    this.notifications.sort(function (a, b) {
      return a.date < b.date ? 1 : -1;
    });

    //set the unread notifications in bold
    this.unreadNotifications = this.notifications.filter(
      (notification) => notification.read == false
    );
  }

  isSameDay(notificationDate) {
    let todaysDate = new Date();
    notificationDate = new Date(notificationDate);
    return (
      notificationDate.getFullYear() === todaysDate.getFullYear() &&
      notificationDate.getDate() === todaysDate.getDate() &&
      notificationDate.getMonth() === todaysDate.getMonth()
    );
  }

  // markAsUnread(notification) {
  //   notification.read = false;
  //   this.unreadNotifications = this.notifications.filter((notification) => notification.read == false);
  // }

  // markAsRead(notification) {
  //   notification.read = true;
  //   this.unreadNotifications = this.notifications.filter((notification) => notification.read == false);
  // }

  async notificationTab(open: boolean) {
    if (open) {
      //when opened, change unread notifications to zero (but keep unread in bold so they know which ones are new)
      this.unreadNotifications = [];
    }
    //if closed
    if (!open) {
      let notificationIDs = [];
      //make all notifications read
      for (let notification of this.notifications) {
        notification.read = true;
        notificationIDs.push(notification._id);
      }

      //save them all as read
      await this.notificationsService.markAsRead(notificationIDs);
    }
  }

  ngAfterViewInit(): void {
    this.refreshView();
  }

  async openSurvey() {
    const modalRef = this.modalService.open(SurveyModalComponent, {
      size: "lg",
      windowClass: "modal-xl",
      backdrop: "static",
    });
    const confirmed = await modalRef.result;
    if (confirmed) {
      window.location.href = sessionStorage.getItem("previousUrl");
    }
  }

  refreshView() {
    const iconElement = document.getElementsByClassName("toggle-icon");
    const menuColorElement = document.getElementsByClassName("main-menu");
    const navigationElement = document.getElementsByClassName("main-menu");
    const navbarElement = document.getElementsByClassName("header-navbar");
    const themeColorElement = document.getElementsByClassName("header-navbar");
    const element = document.getElementsByClassName("navbar-header");
    const boxelement = document.getElementById("customizer");
    if (iconElement && iconElement.item(0)) {
      if (
        this._themeSettingsConfig.colorTheme === "semi-light" ||
        this._themeSettingsConfig.colorTheme === "light"
      ) {
        this._renderer.removeClass(iconElement.item(0), "white");
        this._renderer.addClass(iconElement.item(0), "blue-grey");
        this._renderer.addClass(iconElement.item(0), "darken-3");
      } else if (
        this._themeSettingsConfig.colorTheme === "semi-dark" ||
        this._themeSettingsConfig.colorTheme === "dark"
      ) {
        this._renderer.addClass(iconElement.item(0), "white");
        this._renderer.removeClass(iconElement.item(0), "blue-grey");
        this._renderer.removeClass(iconElement.item(0), "darken-3");
      }
    }

    if (this._themeSettingsConfig) {
      if (this._themeSettingsConfig.colorTheme === "semi-light") {
        this.selectedHeaderNavBarClass = this._themeSettingsConfig.color;
        this.selectedNavBarHeaderClass = "";
      } else if (this._themeSettingsConfig.colorTheme === "semi-dark") {
        this.selectedNavBarHeaderClass = this._themeSettingsConfig.color;
        this.selectedHeaderNavBarClass = "";
      } else if (this._themeSettingsConfig.colorTheme === "dark") {
        this.selectedHeaderNavBarClass = this._themeSettingsConfig.color;
        this.selectedNavBarHeaderClass = "";
      } else if (this._themeSettingsConfig.colorTheme === "light") {
        this.selectedHeaderNavBarClass = this._themeSettingsConfig.color;
        this.selectedNavBarHeaderClass = this._themeSettingsConfig.color;
      }
    }

    if (menuColorElement && this._themeSettingsConfig) {
      if (this._themeSettingsConfig.menuColor === "menu-dark") {
        this._renderer.removeClass(menuColorElement.item(0), "menu-light");
        this._renderer.addClass(menuColorElement.item(0), "menu-dark");
      } else if (this._themeSettingsConfig.menuColor === "menu-light") {
        this._renderer.removeClass(menuColorElement.item(0), "menu-dark");
        this._renderer.addClass(menuColorElement.item(0), "menu-light");
      }
    }

    if (themeColorElement && this._themeSettingsConfig) {
      if (this._themeSettingsConfig.colorTheme === "semi-light") {
        this._renderer.removeClass(
          themeColorElement.item(0),
          "navbar-semi-dark"
        );
        this._renderer.removeClass(themeColorElement.item(0), "navbar-dark");
        this._renderer.removeClass(themeColorElement.item(0), "navbar-light");
      } else if (this._themeSettingsConfig.colorTheme === "semi-dark") {
        this._renderer.removeClass(
          themeColorElement.item(0),
          "navbar-semi-light"
        );
        this._renderer.removeClass(themeColorElement.item(0), "navbar-dark");
        this._renderer.removeClass(themeColorElement.item(0), "navbar-light");
      } else if (this._themeSettingsConfig.colorTheme === "dark") {
        this._renderer.removeClass(
          themeColorElement.item(0),
          "navbar-semi-dark"
        );
        this._renderer.removeClass(
          themeColorElement.item(0),
          "navbar-semi-dark"
        );
        this._renderer.removeClass(themeColorElement.item(0), "navbar-light");
      } else if (this._themeSettingsConfig.colorTheme === "light") {
        this._renderer.removeClass(
          themeColorElement.item(0),
          "navbar-semi-dark"
        );
        this._renderer.removeClass(themeColorElement.item(0), "navbar-dark");
        this._renderer.removeClass(
          themeColorElement.item(0),
          "navbar-semi-light"
        );
      }
    }

    if (navigationElement && this._themeSettingsConfig) {
      if (this._themeSettingsConfig.navigation === "menu-native-scroll") {
        this._renderer.addClass(
          navigationElement.item(0),
          "menu-native-scroll"
        );
      } else if (this._themeSettingsConfig.navigation === "menu-icon-right") {
        this._renderer.addClass(navigationElement.item(0), "menu-icon-right");
      } else if (this._themeSettingsConfig.navigation === "menu-bordered") {
        this._renderer.addClass(navigationElement.item(0), "menu-bordered");
      } else if (this._themeSettingsConfig.navigation === "menu-flipped") {
        this._renderer.addClass(document.body, "menu-flipped");
      } else if (this._themeSettingsConfig.navigation === "menu-collapsible") {
        this._renderer.addClass(navigationElement.item(0), "menu-collapsible");
      } else if (this._themeSettingsConfig.navigation === "menu-static") {
        this._renderer.addClass(navigationElement.item(0), "menu-static");
      }
    }

    if (navbarElement && this._themeSettingsConfig) {
      if (this._themeSettingsConfig.menu === "navbar-static-top") {
        this._renderer.addClass(navbarElement.item(0), "navbar-static-top");
        this._renderer.addClass(navigationElement.item(0), "menu-static");
      }
    }

    if (navbarElement && this._themeSettingsConfig) {
      if (this._themeSettingsConfig.menu === "semi-light") {
        this._renderer.addClass(
          navbarElement.item(0),
          "navbar-semi-light bg-gradient-x-grey-blue"
        );
      } else if (this._themeSettingsConfig.menu === "semi-dark") {
        this._renderer.addClass(navbarElement.item(0), "navbar-semi-dark");
      } else if (this._themeSettingsConfig.menu === "dark") {
        this._renderer.addClass(navbarElement.item(0), "navbar-dark");
      } else if (this._themeSettingsConfig.menu === "light") {
        this._renderer.addClass(navbarElement.item(0), "navbar-light");
      }
    }
  }

  resetOpenMenu() {
    for (
      let i = 0;
      i < this._menuSettingsConfig.vertical_menu.items.length;
      i++
    ) {
      const menu = this._menuSettingsConfig.vertical_menu.items[i];
      if (!menu.submenu) {
        menu["isOpen"] = false;
        menu["isActive"] = false;
        menu["hover"] = false;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          menu["isOpen"] = false;
          menu["isActive"] = false;
          menu["hover"] = false;
          menu.submenu.items[j]["isOpen"] = false;
        }
      }
    }
  }

  setOpenInNavbar(value) {
    for (
      let i = 0;
      i < this._menuSettingsConfig.vertical_menu.items.length;
      i++
    ) {
      const menu = this._menuSettingsConfig.vertical_menu.items[i];
      if (!menu.submenu && menu.page === this.router.url) {
        menu["isOpen"] = value;
        menu["isActive"] = value;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          if (menu.submenu.items[j].page === this.router.url) {
            menu["isOpen"] = value;
            menu["isActive"] = value;
            menu.submenu.items[j]["isOpen"] = value;
            menu.submenu.items[j]["isActive"] = value;
            break;
          }
        }
      }
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseEnter(e) {
    if (this.navbarService.isFixedMenu()) {
      return;
    }
    this.navbarService.setMouseInRegion(true);
    const navBar = this.document.getElementById("navbar-header");
    const mainMenu = this.document.getElementById("main-menu");

    // check if the left aside menu is fixed
    if (!navBar.classList.contains("expanded")) {
      this._renderer.addClass(navBar, "expanded");
      this._renderer.addClass(mainMenu, "expanded");
      this.resetOpenMenu();
      this.setOpenInNavbar(true);
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseLeave(event) {
    if (this.navbarService.isFixedMenu()) {
      return;
    }
    const _self = this;
    const navBar = this.document.getElementById("navbar-header");
    const mainMenu = this.document.getElementById("main-menu");
    if (navBar && navBar.classList.contains("expanded")) {
      this.insideTm = setTimeout(() => {
        if (!_self.navbarService.isMouseInRegion()) {
          this._renderer.removeClass(navBar, "expanded");
          this._renderer.removeClass(mainMenu, "expanded");
          this.resetOpenMenu();
          this.setOpenInNavbar(false);
        }
      }, 100);
    }
    this.navbarService.setMouseInRegion(false);
  }

  // example to update badge value dynamically from another component
  updateMenuBadgeValue() {
    for (let i = 0; i < this._menuSettingsConfig.items.length; i++) {
      if (this._menuSettingsConfig.items[i].badge) {
        this._menuSettingsConfig.items[i].badge.value = 19;
      }
    }
    this._menuSettingsService.config = this._menuSettingsConfig;
  }

  handleCollapseOfMenu(element) {
    if (
      element.classList &&
      element.classList.contains("has-sub") &&
      element.classList.contains("open")
    ) {
      element.classList.remove("open");
      element.classList.remove("hover");
      element.classList.add("menu-collapsed-open");
    }
  }

  handleExpandOfMenu(element) {
    if (
      element.classList &&
      element.classList.contains("has-sub") &&
      element.classList.contains("menu-collapsed-open")
    ) {
      element.classList.remove("menu-collapsed-open");
      element.classList.add("open");
      element.classList.add("hover");
    }
  }

  toggleMenu(event) {
    const target = event.target || event.srcElement || event.currentTarget;
    const parent = target.parentElement;
    if (parent && parent.classList.contains("has-sub")) {
      this.openSubMenuUsingParent(parent);
    } else {
      const parentOfParent = parent.parentElement;
      this.openSubMenuUsingParent(parentOfParent);
    }
  }

  openSubMenuUsingParent(parent) {
    if (
      parent.classList &&
      parent.classList.contains("has-sub") &&
      !parent.classList.contains("open")
    ) {
      parent.classList.add("open");
    } else if (
      parent.classList &&
      parent.classList.contains("has-sub") &&
      parent.classList.contains("open")
    ) {
      parent.classList.remove("open");
    }
  }

  toggleFullScreen() {
    const toggleIcon = document.getElementsByClassName("ficon");

    if (toggleIcon.item(0).classList.contains("ft-maximize")) {
      this.openfullscreen();
      this._renderer.removeClass(toggleIcon.item(0), "ft-maximize");
      this._renderer.addClass(toggleIcon.item(0), "ft-minimize");
    } else if (toggleIcon.item(0).classList.contains("ft-minimize")) {
      this.closefullscreen();
      this._renderer.addClass(toggleIcon.item(0), "ft-maximize");
      this._renderer.removeClass(toggleIcon.item(0), "ft-minimize");
    }
  }

  openfullscreen() {
    // Trigger fullscreen
    // tslint:disable-next-line: no-shadowed-variable
    const docElmWithBrowsersFullScreenFunctions =
      document.documentElement as HTMLElement & {
        mozRequestFullScreen(): Promise<void>;
        webkitRequestFullscreen(): Promise<void>;
        msRequestFullscreen(): Promise<void>;
      };

    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) {
      /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) {
      /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }
  }

  back() {
    this.location.back();
  }

  closefullscreen() {
    // tslint:disable-next-line: no-shadowed-variable
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) {
      /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) {
      /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
  }

  toggleFixMenu(e) {
    if (this.document.body.classList.contains("menu-expanded")) {
      // show the left aside menu
      this.navbarService.setFixedMenu(false);
      this.document.body.classList.remove("menu-expanded");
      this.document.body.classList.add("menu-collapsed");
      // Change switch icon
      this._themeSettingsConfig.menu = "collapse";
    } else {
      this.navbarService.setFixedMenu(true);
      this.document.body.classList.remove("menu-collapsed");
      this.document.body.classList.add("menu-expanded");
      // Change switch icon
      this._themeSettingsConfig.menu = "expand";
    }
    const navBar = this.document.getElementById("navbar-header");
    const mainMenu = this.document.getElementById("main-menu");
    this._renderer.addClass(navBar, "expanded");
    this._renderer.addClass(mainMenu, "expanded");
    setTimeout(() => {
      AppConstants.fireRefreshEventOnWindow();
    }, 300);
  }

  toggleNavigation(e) {
    const sidenav = document.getElementById("sidenav-overlay");
    const sidebarLeft =
      document.getElementById("sidebar-left") ||
      document.getElementById("email-app-menu") ||
      document.getElementById("sidebar-todo");
    const contentOverlay = document.getElementById("content-overlay");

    if (
      this.document.body.classList.contains("menu-open") &&
      (this.router.url === "/todos" ||
        this.router.url === "/contacts" ||
        this.router.url === "/email" ||
        this.router.url === "/chats" ||
        this.router.url === "/chats/static-chat")
    ) {
      this.document.body.classList.remove("menu-open");
      this._renderer.removeClass(sidenav, "d-block");
      this._renderer.removeClass(contentOverlay, "show");
      this.document.body.classList.add("menu-close");
      this._renderer.addClass(sidenav, "d-none");
      this.showNavbar = false;
    } else if (this.document.body.classList.contains("menu-open")) {
      this.document.body.classList.remove("menu-open");
      this._renderer.removeClass(sidenav, "d-block");
      this.document.body.classList.add("menu-close");
      this._renderer.addClass(sidenav, "d-none");
      this.showNavbar = false;
    } else {
      this._renderer.removeClass(sidenav, "d-none");
      this.document.body.classList.remove("menu-close");
      this.document.body.classList.add("menu-open");
      this._renderer.addClass(sidenav, "d-block");
      this.showNavbar = false;
    }

    if (sidebarLeft) {
      this._renderer.removeClass(sidebarLeft, "show");
      this._renderer.removeClass(contentOverlay, "show");
    }
  }

  toggleNavbar(e) {
    if (this.showNavbar) {
      this.showNavbar = false;
    } else {
      this.showNavbar = true;
    }
  }

  public clickSearch() {
    if (this.isHeaderSearchOpen) {
      this.isHeaderSearchOpen = false;
    } else {
      this.isHeaderSearchOpen = true;
    }
  }

  schoolSelect() {
    sessionStorage.setItem("previousUrl", this.router.url);
    sessionStorage.removeItem("currentLayoutStyle");
    window.location.href = "/school-select";
  }

  groupSelect() {
    sessionStorage.setItem("previousUrl", this.router.url);
    sessionStorage.removeItem("currentLayoutStyle");
    window.location.href = "/school-group-select";
  }

  async academicYearSelect() {
    sessionStorage.setItem("previousUrl", this.router.url);
    sessionStorage.removeItem("currentLayoutStyle");

    try {
      let confirmed;
      const modalRef = this.modalService.open(
        AcademicYearSelectorModalComponent,
        {
          windowClass: "animated fadeInDown",
        }
      );
      confirmed = await modalRef.result;
      if (confirmed) {
        window.location.href = sessionStorage.getItem("previousUrl");
      }
    } catch (error) {
      await this.errorService.handleError(error, true);
    }
  }

  selectGlobalAdmin() {
    this.currentUser.school = null;
    this.currentUser.school_group = null;
    this.currentUser.academic_year = null;
    sessionStorage.setItem("currentUser", JSON.stringify(this.currentUser));
    window.location.href = "/global-admin/dashboard";
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (event.target.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH) {
      this.isMobile = true;
      this.showNavbar = false;
    } else {
      this.isMobile = false;
      this.showNavbar = true;
    }
  }

  openSupportModal() {
    if (this.currentUser.school != null || this.groupDashboard === true) {
      const modalRef = this.modalService.open(SupportRequestModalComponent, {
        windowClass: "animated fadeInDown",
      });
      modalRef.result.then((result) => {
        if (result) {
          this.toastr.success("Support Request", "Sent");
        }
      });
    }
  }
}
