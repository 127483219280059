import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { AuthService } from "../../_services/auth.service";
import { AlertService } from "../../_services/alert.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { UsersService } from "src/app/_services/users/users.service";
import { ErrorService } from "../../_services/global/error.service";

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.css"],
})
export class PasswordResetComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  viewPassword = false;

  helper = new JwtHelperService();
  public code: any;
  public currentUser: any;
  public invalidCode: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService,
    public userService: UsersService,
    public errorService: ErrorService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.code = params["code"];
    });
  }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      password: [
        null,
        [
          Validators.required,
          Validators.pattern(
            "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
          ),
        ],
      ],
      confirm_password: [
        null,
        [Validators.required, this.matchOtherValidator("password")],
      ],
    });

    await this.getData();
  }

  async getData() {
    this.loading = true;

    if (!this.code) {
      this.invalidCode = true;
      return;
    }

    try {
      this.currentUser = await this.userService.checkPasswordResetExists(
        this.code
      );
    } catch (error) {
      let retry;
      if (
        error.status &&
        (error.status === 404 || error.status === 400) &&
        error.error &&
        error.error.message
      ) {
        this.invalidCode = error.error.message;
        return;
      }
      retry = await this.errorService.handleError(error, true);
      if (retry) {
        await this.getData();
      }
    } finally {
      this.loading = false;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  async submit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    try {
      await this.userService.editPasswordByCode(
        this.code,
        this.form.value.password
      );

      this.router.navigate(["/login"], { queryParams: { reset: "true" } });
    } catch (error) {
      this.submitted = false;
      this.alertService.error(error.message);
    }
  }

  matchOtherValidator(otherControlName: string) {
    let thisControl: FormControl;
    let otherControl: FormControl;

    return function matchOtherValidate(control: FormControl) {
      if (!control.parent) {
        return null;
      }

      // Initializing the validator.
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as FormControl;
        if (!otherControl) {
          throw new Error(
            "matchOtherValidator(): other control is not found in parent group"
          );
        }
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }

      if (!otherControl) {
        return null;
      }

      if (otherControl.value !== thisControl.value) {
        return {
          matchOther: true,
        };
      }

      return null;
    };
  }

  toggleViewPassword() {
    this.viewPassword = !this.viewPassword;
  }
}
