import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PublicLayoutComponent } from "./_layout/public-layout/public-layout.component";
import { PrivateLayoutComponent } from "./_layout/private-layout/private-layout.component";
import { AuthGuard } from "./_guards/auth.guard";

import { LoginComponent } from "./pages/login";
import { DocumentsSignComponent } from "./pages/register-school/documents-sign";

import { PasswordResetComponent } from "./pages/password-reset/password-reset.component";
import { RegisterSchoolComponent } from "./pages/register-school/register-school.component";

import { ForgotPasswordComponent } from "./pages/login/forgot-password/forgot-password.component";
import { MsalGuard } from "@azure/msal-angular";
import { MaintenanceResolver } from "./_services/global/maintenance-resolver.service";
import { MaintenanceComponent } from "./pages/maintenance/maintenance.component";

const appRoutes: Routes = [
	{
		path: "error",
		loadChildren: () =>
			import("../app/_components/errors/errors.module").then(
				(m) => m.ErrorsModule
			),
	},

	{
		path: "maintenance",
		component: MaintenanceComponent,
	},

	// To navigate to the login page if the url doesn't have /login
	{
		path: "",
		component: LoginComponent,
		canActivate: [AuthGuard],
		resolve: { maintenance: MaintenanceResolver },
	},
	// Public layout
	{
		path: "",
		component: PublicLayoutComponent,
		resolve: { maintenance: MaintenanceResolver },
		children: [
			{ path: "login", component: LoginComponent },
			{ path: "forgot-password", component: ForgotPasswordComponent },
			{
				path: "password-reset-request",
				component: PasswordResetComponent,
			},
			{
				path: "register-school-list/:groupName",
				component: RegisterSchoolComponent,
			},

			{
				path: "school/documents-sign",
				component: DocumentsSignComponent,
			},
		],
	},

	{
		path: "school-select",
		resolve: { maintenance: MaintenanceResolver },
		loadChildren: () =>
			import("../app/pages/school-select/school-select.module").then(
				(m) => m.SchoolSelectModule
			),
		canActivate: [AuthGuard],
	},

	{
		path: "school-group-select",
		resolve: { maintenance: MaintenanceResolver },
		loadChildren: () =>
			import("./pages/school-group-select/school-group-select.module").then(
				(m) => m.SchoolGroupSelectModule
			),
		canActivate: [AuthGuard],
	},

	{
		path: "",
		resolve: { maintenance: MaintenanceResolver },
		component: PrivateLayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: "logout",
				component: LoginComponent,
			},

			{
				path: "global-admin",
				loadChildren: () =>
					import("./pages/global-admin.module").then(
						(m) => m.GlobalAdminModule
					),
			},
			{
				path: "school",
				loadChildren: () =>
					import("./pages/school.module").then((m) => m.SchoolModule),
			},

			{
				path: "school-group",
				loadChildren: () =>
					import("./pages/school-group.module").then((m) => m.GroupModule),
			},

			// {
			//   path: "edit-profile",
			//   loadChildren: () =>
			//     import("./pages/edit-profile/edit-profile.module").then(
			//       (m) => m.EditProfileModule
			//     ),
			// },
		],
	},
	{ path: "**", pathMatch: "full", redirectTo: "error/404" },
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			scrollOffset: [0, 0],
			scrollPositionRestoration: "top",
			// relativeLinkResolution: "legacy",
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}

// export const routing = RouterModule.forRoot(appRoutes, {
//     scrollOffset: [0, 0],
//     scrollPositionRestoration: "top",
// });
