import { Injectable } from "@angular/core";
import {
	Router,
	CanActivate,
	CanActivateChild,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	NavigationEnd,
} from "@angular/router";
// import { userInfo } from "os";
import { AuthService } from "../_services/auth.service";
import { VerticalComponent } from "../_layout/header/vertical/vertical.component";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
	public previousUrl: string = undefined;
	public currentUrl: string = undefined;

	constructor(public router: Router, public authService: AuthService) {
		this.authService.currentUrlSubject.next(router.url);
	}

	public getCurrentUrl() {
		this.currentUrl = this.router.url;
		/**
		 * This was previously done to set the previousUrl
		 */
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.currentUrl = event.url;
			}
		});
		this.authService.currentUrlSubject.next(this.currentUrl);

		return this.currentUrl;
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this.getCurrentUrl();
		if (sessionStorage.getItem("currentUser")) {
			// Logged in so return true
			let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
			let previousUrl = sessionStorage.getItem("previousUrl");
			const currentUrl = state.url;
			this.authService.currentUrlSubject.next(currentUrl);

			// check school permissions
			if (
				!currentUser.global_admin &&
				currentUrl.includes("school/") &&
				currentUser.school
			) {
				if (
					currentUser.school.phase_of_education &&
					currentUser.school.phase_of_education === "Secondary"
				) {
					if (
						currentUrl.includes("school/data-admin") ||
						currentUrl.includes("school/pupil-data-admin") ||
						currentUrl.includes("school/attainment-grids")
					) {
						this.router.navigate(["school/permission-denied"], {
							queryParams: {
								permissionType: "GlobalAdminAccessOnly",
							},
						});
					}
				}
			}
			if (
				!currentUser.global_admin &&
				currentUrl.includes("school-group/") &&
				currentUser.school_group
			) {
				if (
					currentUrl.includes("school-group/data-admin") ||
					currentUrl.includes("school-group/pupil-data-admin") ||
					currentUrl.includes("school-group/attainment-grids")
				) {
					this.router.navigate(["school-group/permission-denied"], {
						queryParams: { permissionType: "GlobalAdminAccessOnly" },
					});
				}
			}

			if (
				!currentUser.school &&
				currentUser.global_admin &&
				(previousUrl === null || !previousUrl.includes("global-admin")) &&
				state.url.includes("school/") &&
				!state.url.includes("global-admin")
			) {
				this.router.navigate(["/global-admin/dashboard"]);
				return true;
			}

			// loading school-group page after logging in as a school-group user
			if (
				!currentUser.global_admin &&
				currentUser.school_group &&
				state.url.includes("school/")
			) {
				this.router.navigate(["/school-group/dashboard"]);
				return true;
			}

			if (
				!currentUser.school &&
				!currentUser.school_group &&
				!currentUser.global_admin
			) {
				sessionStorage.removeItem("currentUser");
				sessionStorage.removeItem("remember");
				this.router.navigate(["/login"]);
			}

			if (!currentUser.global_admin && state.url.includes("global-admin")) {
				this.router.navigate([previousUrl]);
			}

			if (
				currentUser.school &&
				// !currentUser.school.signed_documents.length &&
				currentUser.school.has_unsigned_documents &&
				!currentUser.global_admin &&
				!state.url.includes("documents-sign") &&
				!state.url.includes("school-select")
			) {
				this.router.navigate(["/school/documents-sign"]);
			}
			//expired session
			const authToken = JSON.parse(sessionStorage.getItem("auth_token"));
			if (authToken.expires < Date.now() / 1000) {
				this.authService.doLogout({ expired: true });
			}
			return true;
		}
		// Not logged in so redirect to login page with the return url
		this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
		return false;
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		return this.canActivate(route, state);
	}
}
