// Default menu settings configurations
import {
  faHome,
  faUsersClass,
  faChartBar,
  faUsersCog,
  faClipboardListCheck,
  faFileAlt,
  faChartArea,
  faChartScatter,
  faBookReader,
  faDownload,
  faQuestionCircle,
  faShoppingCart,
  faCity,
  faUniversity,
  faListAlt,
  faLayerPlus,
  faCode,
  faUserLock,
  faCreditCard,
  faLink,
  faFlag,
  faPoundSign,
  faEye,
  faBug,
  faTools,
  faSuitcase,
  faInfo,
  faInfoCircle,
  faAlarmExclamation,
  faBell,
  faClock,
  faAlarmClock,
  faCalendar,
  faPollPeople,
} from "@fortawesome/pro-light-svg-icons";
import { faInfoSquare } from "@fortawesome/pro-solid-svg-icons";
import { MenuSettingsService } from "./menu-settings.service";

const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
const academicYearID =
  currentUser && currentUser.academic_year
    ? currentUser.academic_year._id
    : null;
const service = MenuSettingsService;
export interface MenuItem {
  title: string;
  icon: any;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string; value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
  globalAdmin: boolean;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[];
  };
  vertical_menu: {
    items: Partial<MenuItem>[];
  };
  primary_menu: {
    items: Partial<MenuItem>[];
  };
  secondary_menu: {
    items: Partial<MenuItem>[];
  };
  global_admin_menu: {
    items: Partial<MenuItem>[];
  };
  group_menu: {
    items: Partial<MenuItem>[];
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    items: [],
  },
  vertical_menu: {
    items: [],
  },
  primary_menu: {
    items: [
      { section: "Home" },
      {
        title: "Dashboard",
        icon: faHome,
        page: "/school/dashboard",
      },

      {
        title: "Notifications",
        icon: faBell,
        page: "/school/notifications",
      },
      {
        title: "Data Progress Report",
        icon: faChartBar,
        page: "/school/primary-data-progress-report",
      },
      { section: "Pupils" },
      {
        title: "View Pupils",
        icon: faUsersClass,
        page: "/school/pupils",
      },
      {
        title: "Pupils with Flags",
        icon: faUsersClass,
        page: "/school/pupils-with-flags",
      },
      {
        title: "SEND",
        icon: faUsersClass,
        page: "/school/send-pupils",
      },
      {
        title: "Add Data",
        icon: faFileAlt,
        page: "/school/add-data-menu",
      },
      {
        title: "Connected Schools",
        icon: faLink,
        page: "/school/connected-schools",
      },
      { section: "Setup" },
      {
        title: "Add/Edit Pupils",
        icon: faUsersCog,
        page: "/school/add-edit-pupils",
      },
      {
        title: "Add/Edit Users",
        icon: faUsersCog,
        page: "/school/user-management",
      },
      {
        title: "Agreements",
        icon: faFileAlt,
        page: "/school/agreements",
      },
      { section: "Help" },

      {
        title: "Knowledge Hub",
        icon: faSuitcase,
        page: "/school/knowledge-hub",
      },
      {
        title: "Support",
        icon: faQuestionCircle,
        page: null,
      },
    ],
  },
  secondary_menu: {
    items: [
      { section: "Home" },
      {
        title: "Dashboard",
        icon: faHome,
        page: "/school/dashboard",
      },
      {
        title: "Notifications",
        icon: faBell,
        page: "/school/notifications",
      },
      {
        title: "Progress Report",
        icon: faChartBar,
        page: "/school/secondary-progress-report",
      },
      {
        title: "Transition Summary",
        icon: faChartArea,
        page: "/school/school-transition-summary",
      },
      { section: "Your Pupils" },
      {
        title: "View Pupils",
        icon: faUsersClass,
        page: "/school/pupils",
      },
      {
        title: "Pupils with Flags",
        icon: faFlag,
        page: "/school/pupils-with-flags",
      },
      {
        title: "SEND",
        icon: faUsersClass,
        page: "/school/send-pupils",
      },
      {
        title: "View Data",
        icon: faFileAlt,
        page: "/school/add-data-menu",
      },
      { section: "Setup" },
      {
        title: "Add/Edit Pupils",
        icon: faUsersCog,
        page: "/school/add-edit-pupils",
      },
      {
        title: "Add/Edit Users",
        icon: faUsersCog,
        page: "/school/user-management",
      },
      {
        title: "Connected Schools",
        icon: faLink,
        page: "/school/connected-schools",
      },
      {
        title: "Agreements",
        icon: faFileAlt,
        page: "/school/agreements",
      },
      { section: "Help" },

      {
        title: "Knowledge Hub",
        icon: faSuitcase,
        page: "/school/knowledge-hub",
      },

      {
        title: "Support",
        icon: faQuestionCircle,
        page: null,
      },
    ],
  },
  group_menu: {
    items: [
      { section: "Home" },
      {
        title: "Dashboard",
        icon: faHome,
        page: "/school-group/dashboard",
      },
      {
        title: "Schools Progress",
        icon: faChartBar,
        page: "/school-group/group-schools-progress",
      },
      {
        title: "Transition Summary",
        icon: faChartArea,
        page: "/school-group/group-transition-summary",
      },
      {
        title: "Survey Analysis",
        icon: faChartScatter,
        page: "/school-group/group-survey-analysis",
      },
      { section: "Schools" },
      {
        title: "Schools",
        icon: faListAlt,
        page: "/school-group/school-groups/add-edit",
      },
      {
        title: "Admissions Upload",
        icon: faUsersClass,
        page: "/school-group/admissions-upload",
      },
      { section: "Setup" },
      {
        title: "Add/Edit Users",
        icon: faUsersCog,
        page: "/school-group/user-management",
      },
      {
        title: "Agreements",
        icon: faFileAlt,
        page: "/school-group/agreements",
      },
      {
        title: "Data Set Up",
        icon: faListAlt,
        page: "/school-group/data-admin-menu",
        globalAdmin: true,
      },
      {
        title: "Alerts",
        icon: faAlarmExclamation,
        page: "/school-group/alerts",
      },
      {
        title: "Survey Forms",
        icon: faPollPeople,
        page: "/school-group/dynamic-forms",
      },

      {
        title: "Key Dates",
        icon: faCalendar,
        page: "/school-group/key-dates",
      },
      {
        title: "Additional Information",
        icon: faInfoSquare,
        page: "/school-group/group-additional-information",
      },
      { section: "Help" },
      {
        title: "Knowledge Hub",
        icon: faSuitcase,
        page: "/school-group/knowledge-hub",
      },
      {
        title: "Support",
        icon: faQuestionCircle,
        page: null,
      },
    ],
  },
  global_admin_menu: {
    items: [
      { section: "Home" },
      {
        title: "Dashboard",
        icon: faHome,
        page: "/global-admin/dashboard",
      },
      {
        title: "Survey Analysis",
        icon: faChartScatter,
        page: "/global-admin/survey-analysis",
      },
      { section: "Schools/Groups" },
      {
        title: "Active Schools",
        icon: faUniversity,
        page: "/global-admin/schools/active",
        submenu: {
          items: [
            {
              title: "All Primary Schools",
              icon: faUniversity,
              page: "/global-admin/schools/all/primary",
            },
            {
              title: "All Secondary Schools",
              icon: faUniversity,
              page: "/global-admin/schools/all/secondary",
            },
            {
              title: "All Other Schools",
              icon: faUniversity,
              page: "/global-admin/schools/all/other",
            },
          ],
        },
      },
      {
        title: "School Groups",
        icon: faCity,
        page: "/global-admin/school-groups",
        submenu: {
          items: [
            {
              title: "La Codes",
              icon: faListAlt,
              page: "/global-admin/la-codes",
            },
          ],
        },
      },
      {
        title: "User Management",
        icon: faUsersCog,
        page: "/global-admin/user-management",
      },
      { section: "Set Up" },
      {
        title: "Academic Years",
        icon: faLayerPlus,
        page: "/global-admin/academic-years",
      },
      {
        title: "Alerts",
        icon: faListAlt,
        page: "/global-admin/alerts",
      },
      {
        title: "Data Admin",
        icon: faListAlt,
        page: "/global-admin/data-admin-global",
        submenu: {
          items: [
            {
              title: "Add/Edit Data Categories",
              icon: faListAlt,
              page: "/global-admin/pupil-data-admin",
            },
            {
              title: "Add/Edit Attainment Grids",
              icon: faListAlt,
              page: "/global-admin/attainment-grids",
            },
          ],
        },
      },
      {
        title: "Documents",
        icon: faFileAlt,
        page: "/global-admin/documents",
        submenu: {
          items: [
            {
              title: "Document Categories",
              icon: faFileAlt,
              page: "/global-admin/document-categories",
            },
            {
              title: "Document Types",
              icon: faFileAlt,
              page: "/global-admin/document-types",
            },
          ],
        },
      },
      {
        title: "Survey Forms",
        icon: faListAlt,
        page: "/global-admin/dynamic-forms",
      },
      {
        title: "Information Modals",
        icon: faListAlt,
        page: "/global-admin/information-modals",
      },

      {
        title: "Page Descriptions",
        icon: faListAlt,
        page: "/global-admin/page-descriptions",
      },
      {
        title: "User Guides",
        icon: faListAlt,
        page: "/global-admin/user-guides",
        submenu: {
          items: [
            {
              title: "User Guide Categories",
              icon: faListAlt,
              page: "/global-admin/user-guide-categories",
            },
          ],
        },
      },
      {
        title: "Dev",
        icon: faCode,
        page: "/global-admin/dev",
      },
    ],
  },
};
