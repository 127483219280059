<div class="maintenance-page">
	<div class="maintenance-page-content">
		<img
			class="maintenance-page-logo"
			src="assets/images/logo/logo.svg"
			alt="SixIntoSeven"
		/>
		<div class="maintenance-page-text">
			<h2>SixIntoSeven 2023 System cut-off</h2>
			<p>
				The current edition of the SixIntoSeven portal will be unavailable
				starting from Friday, January 5th, 2024. This system cut-off is
				necessary as we prepare to launch an exciting new version in February.
			</p>
			<p>
				All schools will be contacted soon with a new link to access
				SixIntoSeven 2024.
			</p>
			<h2>New features include -</h2>
			<p>
				<strong>Improved interface –</strong> with a user-friendly design.<br />
				<strong>SEND & Vulnerable pupil enhancements –</strong> to capture &
				share voice memos.<br />
				<strong>Improved reports for cohort analysis -</strong> at LA and school
				level.<br />
				<strong>Enhanced GDPR compliance -</strong> for out-of-area transition
				of pupils and access to data.<br />
				<strong>Improved user guides -</strong> while working in SixIntoSeven.
			</p>
			<p>
				Our team look forward to working with you and supporting your transition
				of pupils to secondary school. If you have any questions or concerns,
				please feel free to reach out to our support team at
				<a href="mailto:support@sixintoseven.co.uk"
					>support@sixintoseven.co.uk</a
				>
				or <strong>0333 880 4403</strong>
			</p>
			<p>Thank you for your continued partnership. The SixIntoSeven Team</p>
		</div>
	</div>
</div>
