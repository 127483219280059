<div class="modal-header">
  <h4 class="modal-title">Email us for a quick response</h4>
  <a (click)="close()"
    ><i class="fa fa-times tutorial-close text-success"></i>
  </a>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <input
      pInputText
      placeholder="Name"
      type="text"
      required
      class="form-control"
      formControlName="userName"
    />
    <div
      *ngIf="submitted && f.userName.errors"
      class="invalid-feedback"
      [class.is-invalid]="submitted && f.userName.errors"
    >
      <div *ngIf="f.userName.errors.required">Name is required</div>
    </div>
    <br />
    <input
      pInputText
      placeholder="Email"
      type="text"
      required
      class="form-control"
      formControlName="userEmail"
    />
    <div
      *ngIf="submitted && f.userEmail.errors"
      class="invalid-feedback"
      [class.is-invalid]="submitted && f.userEmail.errors"
    >
      <div *ngIf="f.userEmail.errors.required">Email is required</div>
    </div>
    <br />
    <input
      pInputText
      placeholder="Institution Name"
      type="text"
      required
      class="form-control"
      [class.is-invalid]="submitted && f.schoolName.errors"
      formControlName="schoolName"
    />
    <div *ngIf="submitted && f.schoolName.errors" class="invalid-feedback">
      <div *ngIf="f.schoolName.errors.required">School Name is required</div>
    </div>
    <br />
    <textarea
      pInputTextarea
      rows="5"
      cols="15"
      class="form-control"
      required
      formControlName="description"
      [class.is-invalid]="submitted && f.description.errors"
      placeholder="Enter your query"
    ></textarea>
    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
      <div *ngIf="f.description.errors.required">Description is required</div>
    </div>
  </form>
</div>

<div class="modal-footer justify-content-center">
  <button
    type="button"
    (click)="close()"
    class="btn btn-size btn-outline-secondary"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-size grey btn-success"
    (click)="submit()"
  >
    Submit
  </button>
</div>
