import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(private http: HttpClient) {}

  //get all
  index() {
    return this.http.get<any>(environment.api.url + "/users").toPromise();
  }

  //get one
  single(userID) {
    return this.http
      .get(environment.api.url + "/users/user/" + userID)
      .toPromise();
  }

  //CRUD
  add(model: any) {
    return this.http
      .post(environment.api.url + "/users/user", model)
      .toPromise();
  }

  edit(userID, model: any) {
    return this.http
      .post(environment.api.url + "/users/user/" + userID, model)
      .toPromise();
  }

  delete(userID, model: any) {
    return this.http
      .post(environment.api.url + "/users/user/" + userID + "/delete", model)
      .toPromise();
  }

  //other functions
  updatePassword(userID, password) {
    return this.http
      .post(environment.api.url + "/users/update-password", {
        user: userID,
        password: password,
      })
      .toPromise();
  }

  checkUserExists(email: any) {
    return this.http
      .post(environment.api.url + "/users/check-user-exists", { email: email })

      .toPromise();
  }

  getAllSchoolUsers(schools) {
    return this.http
      .post<any>(environment.api.url + "/users/get-last-login", {
        schools: schools,
      })
      .toPromise();
  }

  getSchoolsUsersForGroup(schools) {
    return this.http
      .post<any>(environment.api.url + "/users/get-school-users-for-group", {
        schools: schools,
      })
      .toPromise();
  }

  getBySchools(schools) {
    return this.http
      .post<any>(environment.api.url + "/users/get-by-schools", {
        schools: schools,
      })
      .toPromise();
  }

  schools() {
    return this.http.get(environment.api.url + "/users/schools").toPromise();
  }

  getAllUsersByPhase(phase) {
    return this.http
      .get(environment.api.url + "/users/all-users-by-phase/" + phase)
      .toPromise();
  }

  getAllUsersByGroup(phase) {
    return this.http
      .get(environment.api.url + "/users/all-users-by-group/" + phase)
      .toPromise();
  }

  groups() {
    return this.http.get(environment.api.url + "/users/groups").toPromise();
  }

  checkPassword(userID, password) {
    return this.http
      .post(environment.api.url + "/users/check-password", {
        user: userID,
        password: password,
      })
      .pipe(
        map((res) => {
          return res ? true : null;
        })
      )
      .toPromise();
  }

  checkEmailConfirmed(email) {
    return this.http
      .post(environment.api.url + "/users/check-email-confirmed", {
        email: email,
      })

      .toPromise();
  }

  getUserDetails(email) {
    return this.http
      .post(environment.api.url + "/users/get-user-details", {
        email: email,
      })

      .toPromise();
  }

  editPassword(userID, password) {
    return this.http
      .post(environment.api.url + "/users/update-password", {
        user: userID,
        password: password,
      })
      .toPromise();
  }

  editPasswordByCode(code, password) {
    return this.http
      .post(environment.api.url + "/users/update-password-by-code", {
        code: code,
        password: password,
      })
      .toPromise();
  }

  updateLastLogin(userID) {
    return this.http
      .post(
        environment.api.url + "/users/user/" + userID + "/update-last-login",
        null
      )
      .toPromise();
  }

  setDefaultSchool(userID, schoolID) {
    return this.http
      .post(environment.api.url + "/users/set-default-school", {
        user: userID,
        school: schoolID,
      })
      .toPromise();
  }

  setDefaultSchoolGroup(userID, schoolGroupID) {
    return this.http
      .post(environment.api.url + "/users/set-default-school-group", {
        user: userID,
        school_group: schoolGroupID,
      })
      .toPromise();
  }

  sendPasswordReset(email: any) {
    return this.http
      .post(environment.api.url + "/users/send-password-reset", {
        email: email,
      })
      .pipe(
        map((res) => {
          return res ? true : null;
        })
      )
      .toPromise();
  }

  checkPasswordResetExists(code: any) {
    return this.http
      .post(environment.api.url + "/users/check-password-reset-exists", {
        code: code,
      })
      .toPromise();
  }

  getSchoolGroupSchools() {
    return this.http
      .get<any>(environment.api.url + "/users/school-group-schools")
      .toPromise();
  }

  getSchoolGroups() {
    return this.http
      .get<any>(environment.api.url + "/users/school-groups")
      .toPromise();
  }

  getUsers() {
    return this.http
      .get<any>(environment.api.url + "/users/all-users")
      .toPromise();
  }

  getUsersBySchool(schoolID) {
    return this.http
      .get<any>(environment.api.url + "/users/by-school/" + schoolID)
      .toPromise();
  }

  setMainContact(userID) {
    return this.http
      .get<any>(environment.api.url + "/users/set-main-contact/" + userID)
      .toPromise();
  }

  checkEmailTaken(email: any) {
    return this.http
      .post(environment.api.url + "/users/check-email-taken", { email: email })
      .pipe(
        map((res) => {
          return res ? true : null;
        })
      )
      .toPromise();
  }

  deleteEmail(userID: any, email: any) {
    return this.http
      .post(environment.api.url + "/users/delete-email", {
        userID: userID,
        email: email,
      })
      .pipe(
        map((res) => {
          return res ? true : null;
        })
      )
      .toPromise();
  }

  addTransitionLead(model: any) {
    return this.http
      .post(environment.api.url + "/users/add-transition-lead", model)
      .toPromise();
  }

  /**
   * Check if the survey is completed by the user
   */
  async getSurveyComplete(school) {
    const test = await this.http
      .post<any>(environment.api.url + "/users/survey-complete", school)
      .toPromise();
    return test;
  }
}
