import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Router } from "@angular/router";
import { GlobalSettingsService } from "./global-settings.service";

@Injectable({
	providedIn: "root",
})
export class MaintenanceResolver implements Resolve<boolean> {
	constructor(
		private globalSettingsService: GlobalSettingsService,
		private router: Router
	) {}

	async resolve(): Promise<boolean> {
		try {
			const globalSettings =
				await this.globalSettingsService.getGlobalSettings();

			if (globalSettings.maintenance_mode_on) {
				this.router.navigate(["/maintenance"]);
				return false;
			}
			return true;
		} catch (error) {
			console.error("Error fetching global settings:", error);
			return true;
		}
	}
}
