import { Component, OnInit } from "@angular/core";

import { GlobalSettingsService } from "../../_services/global/global-settings.service";
import { Router } from "@angular/router";

@Component({
	selector: "app-maintenance",
	templateUrl: "./maintenance.component.html",
	styleUrls: ["./maintenance.component.css"],
})
export class MaintenanceComponent implements OnInit {
	globalSettings: any = null;

	constructor(
		private globalSettingsService: GlobalSettingsService,
		private router: Router
	) {}

	async ngOnInit() {
		this.globalSettings = await this.globalSettingsService.getGlobalSettings();
		if (!this.globalSettings.mainenance_mode_on) {
			this.router.navigate(["/login"]);
		}
	}
}
