// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firbase and google map related functionality will not work on LIVE instance.

export const environment = {
  production: true,
  api: {
    url: "https://legacy.api.sixintoseven.co.uk",
  },
  msal: {
    tenant: "common",
    clientId: "a7b9110f-1ab4-450b-822c-c2f7b9547ba3",
    redirectUri: "https://legacy.sixintoseven.co.uk/login",
  },
  google: {
    clientId:
      "244361050742-3mn9rotk4ipbmd84r14ntg3fsfpf7t2v.apps.googleusercontent.com",
  },
  stripe_pk: "pk_test_Z0nuedUSwnp544suhN6JJSJH00ShgsI4Lk",
};
