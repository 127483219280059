import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../_services/auth.service";
import { AlertService } from "../../_services/alert.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { DocumentsService } from "../../_services/documents/documents.service";
import { UsersService } from "src/app/_services/users/users.service";
import { InvitesService } from "../../_api/invites/invites.service";
import { ErrorService } from "../../_services/global/error.service";
import { AcademicYearsService } from "src/app/_services/academic-years/academic-years.service";
import { MsalService } from "@azure/msal-angular";
import { SocialAuthService, GoogleLoginProvider } from "angularx-social-login";
import { LgflLoginModalComponent } from "src/app/_components/modals/lgfl-login-modal/lgfl-login-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GlobalSettingsService } from "src/app/_services/global/global-settings.service";

@Component({
	templateUrl: "login.component.html",
	styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
	isPageLoaded = false;
	loginForm: FormGroup;
	loading = false;
	submitted = false;
	returnUrl: string;
	showRegisterMessage = false;
	viewPassword = false;

	helper = new JwtHelperService();

	inviteCode: any;
	invite: any;

	email: any;
	reset;
	checked;
	registerLink = "/register";
	registerQueryParams: any = null;

	maintenance = false;

	globalAdminLogin = false;
	errors: any = [];
	isIframe = false;
	loginDisplay = false;
	reLogin: any;
	noUser: any;

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private alertService: AlertService,
		public authService: AuthService,
		private invitesService: InvitesService,
		private documentsService: DocumentsService,
		private academicYearsService: AcademicYearsService,
		private userService: UsersService,
		private globalSettingsService: GlobalSettingsService,
		private errorService: ErrorService,
		private socialAuth: SocialAuthService,
		private msalService: MsalService,
		private modalService: NgbModal
	) {
		this.route.queryParams.subscribe((params) => {
			this.returnUrl = params["returnUrl"];
			this.inviteCode = params["invite"];
			this.email = params["email"];
			this.reset = params["reset"];
			this.globalAdminLogin =
				params["global_admin_login"] &&
				params["global_admin_login"] === "256478524"
					? true
					: false;
		});
	}

	async ngOnInit() {
		const globalSettings = await this.globalSettingsService.getGlobalSettings();
		if (globalSettings.mainenance_mode_on) {
			this.router.navigate(["/maintenance"]);
		}

		sessionStorage.removeItem("access_token");
		sessionStorage.removeItem("currentUser");

		if (sessionStorage.getItem("failedLogin") === "true") {
			this.alertService.error(
				"Sorry, that email address is already registered on our system, please log in using your email address and password. If you have forgotten your password you can reset it using the link below. If you are trying to register an additional school, you may do this by clicking your user profile after logging in."
			);
		}
		sessionStorage.setItem("failedLogin", "false");

		this.returnUrl =
			this.route.snapshot.queryParams["returnUrl".toString()] || null;
		this.reLogin = this.route.snapshot.queryParams.expired;
		this.noUser = this.route.snapshot.queryParams.error401;

		// if (this.noUser || this.returnUrl) {
		//   this.errors = [
		//     `Not Logged In! You need to be logged in to access this page.`,
		//   ];
		// }
		if (this.reLogin) {
			this.errors = [
				`Your SixIntoSeven session has expired please login again`,
			];
		}
		if (this.inviteCode) {
			this.registerLink = "/register/invited";
			this.registerQueryParams = { invite: this.inviteCode };
			this.invite = await this.invitesService.getInvite(this.inviteCode);
			this.loginForm = this.formBuilder.group({
				email: [this.email, Validators.required],
				password: ["", Validators.required],
				rememberMe: false,
			});
		} else {
			this.loginForm = this.formBuilder.group({
				email: [
					"",
					Validators.compose([Validators.email, Validators.required]),
				],
				password: ["", Validators.required],
				rememberMe: false,
			});
		}

		if (sessionStorage.getItem("currentUser")) {
			this.authService.doLogout();
			this.loading = true;
		}
		this.isPageLoaded = true;
		this.loading = false;
	}

	/**
	 * Microsoft Login
	 * @returns user
	 */

	async login() {
		await this.msalService.instance.setActiveAccount(null);
		// this.msalService.instance.getActiveAccount();
		window.localStorage.clear();
		window.sessionStorage.clear();
		const isIE =
			window.navigator.userAgent.indexOf("MSIE ") > -1 ||
			window.navigator.userAgent.indexOf("Trident/") > -1;

		if (isIE) {
			this.msalService.loginRedirect();
		} else {
			let loginResponse: any;
			let x = false;

			var request = {
				scopes: [],
				prompt: "select_account",
			};

			const result = await this.msalService.loginPopup(request).subscribe(
				async (result) => {
					loginResponse = result;
					await this.msalService.instance.setActiveAccount(result.account);

					const msalToken = loginResponse.idToken;

					try {
						const authToken = await this.authService.microsoftLogin(msalToken);
						sessionStorage.setItem("access_token", authToken.access_token);
						sessionStorage.setItem("login_type", "Microsoft");
						await this.authService.login(authToken);
					} catch (error) {
						this.errors = ["Failed Login"];
						if (error.status === 0) {
							this.errors = ["Server is currently down/under service"];
						}
						// await this.errorService.handleError(error, false, "Failed Login");
					}
				},
				(error) => {
					console.log(error);
				},
				() => {}
			);
		}
	}

	setLoginDisplay() {
		this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
	}

	async microsoftLogin() {
		const isIE = window !== window.parent && !window.opener;
		if (isIE) {
			this.msalService.loginRedirect();
		} else {
			let loginResponse;
			await this.msalService.loginPopup().subscribe({
				next: (result) => {
					loginResponse = result;
				},
				error: (error) => console.log(error),
			});

			let msalToken = null;
			loginResponse ? (msalToken = loginResponse.id_token) : null;
			try {
				const authToken = await this.authService.microsoftLogin(msalToken);
				await this.authService.login(authToken);
			} catch (error) {
				console.error(error);
				this.errors = [error.error.message];
				return error;
			}
		}
	}

	/**
	 * Google Login
	 * @returns user
	 */
	async googleLogin() {
		sessionStorage.removeItem("access_token");
		const userData = await this.socialAuth.signIn(
			GoogleLoginProvider.PROVIDER_ID,
			{ prompt: "select_account" }
		);
		try {
			const authToken = await this.authService.googleLogin(userData.idToken);

			sessionStorage.setItem("access_token", authToken.access_token);
			await this.authService.login(authToken);
		} catch (error) {
			console.error(error);
			this.errors = [error.error.message];
			if (error.status === 0) {
				this.errors = ["Server is currently down/under service"];
			}
			return error;
		}
	}

	/**
	 *  convenience getter for easy access to form fields
	 */
	get f() {
		return this.loginForm.controls;
	}

	async requestEmail() {
		const data = await this.userService.getUserDetails(
			this.f.email.value.toLowerCase().trim()
		);

		this.submitted = true;

		this.loading = true;

		this.loading = false;
	}

	async tryLogin() {
		this.submitted = true;

		// Javascript Validation, stop here if form is invalid
		if (this.loginForm.invalid) {
			return;
		}

		sessionStorage.removeItem("access_token");

		this.loading = true;
		let value = {
			email: this.f.email.value.toLowerCase().trim(),
			password: this.f.password.value,
			school: null,
			school_group: null,
		};

		if (this.inviteCode) {
			value.school = this.invite.school._id;
		}

		try {
			const res: any = await this.authService.newLogin(value);
			await this.authService.login(res);
			//Check to see if user email has been verified
			this.checked = await this.userService.checkEmailConfirmed(
				value.email.toLowerCase().trim()
			);
			let returnUrl = "/school/dashboard";

			sessionStorage.setItem("access_token", res.access_token);
			const decodedToken = this.helper.decodeToken(res.access_token);

			//get ids of latest document versions here

			sessionStorage.removeItem("currentLayoutStyle");

			if (decodedToken.school) {
				let currentAcademicYear: any =
					await this.academicYearsService.getCurrentAcademicYear();
				decodedToken.academic_year = currentAcademicYear;
			}

			if (
				!decodedToken.global_admin &&
				decodedToken.school &&
				decodedToken.school.signed_documents
			) {
				decodedToken.school.has_unsigned_documents = false;

				const latestDocumentVersionIDs: any =
					await this.documentsService.getLatestDocumentVersionIDs(
						decodedToken.school._id
					);

				decodedToken.school.has_unsigned_documents = false;

				for (const latestDocumentVersionID of latestDocumentVersionIDs) {
					if (
						!decodedToken.school.signed_documents.includes(
							latestDocumentVersionID
						)
					) {
						decodedToken.school.has_unsigned_documents = true;
					}
				}

				if (
					decodedToken.school.has_unsigned_documents &&
					!decodedToken.global_admin
				) {
					returnUrl = "/school/documents-sign";
				}
			}

			if (decodedToken.global_admin === true) {
				returnUrl = "/global-admin/dashboard";
			}
			if (decodedToken.global_admin === false && decodedToken.school_group) {
				returnUrl = "/school-group/dashboard";
			}
			if (this.returnUrl) {
				returnUrl = this.returnUrl;
			}

			this.setUserInStorage(decodedToken);

			if (!decodedToken.last_login) {
				returnUrl = "/password-reset";
			}
		} catch (error) {
			let retry;
			if (error.status !== 401) {
				retry = true;
			} else {
				retry = false;
			}
			retry = await this.errorService.handleError(
				error,
				retry,
				"Incorrect Username or Password"
			);
			if (retry) {
				await this.tryLogin();
			}
		} finally {
			this.submitted = false;
			this.loading = false;
		}
	}

	setUserInStorage(res) {
		if (res.user) {
			sessionStorage.setItem("currentUser", JSON.stringify(res.user));
		} else {
			sessionStorage.setItem("currentUser", JSON.stringify(res));
		}
	}

	register() {
		this.showRegisterMessage = true;
	}

	toggleSign(context) {
		const container = document.getElementById("container");
		if (context === "signUp") {
			container.classList.add("right-panel-active");
		}
		if (context === "signIn") {
			container.classList.remove("right-panel-active");
		}
	}

	toggleViewPassword() {
		this.viewPassword = !this.viewPassword;
	}

	async LGFLlogin() {
		//open lgfl login modal
		const modalRef = this.modalService.open(LgflLoginModalComponent, {
			size: "lg",
			windowClass: "animated fadeInDown",
		});

		let value = await modalRef.result;

		const authToken: any = await this.authService.newLogin(value);
		sessionStorage.setItem("access_token", authToken.access_token);
		await this.authService.login(authToken);

		// const authToken = await this.authService.googleLogin(userData.idToken);

		// sessionStorage.setItem("access_token", authToken.access_token);
		// await this.authService.login(authToken);
	}
	// async addError() {
	//   this.errors = [
	//     `Not Logged In! You need to be logged in to access this page.`,
	//   ];
	// }
}
