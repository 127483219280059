<div class="app-content content login-page">
  <div class="content-wrapper">
    <div class="content-body">
      <section class="flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-md-6 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
              <div class="card-header border-0 pb-0">
                <div class="card-title text-center">
                  <img
                    class="login-logo"
                    src="assets/images/logo/logo.svg"
                    alt="branding logo"
                  />
                </div>
                <h6
                  class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"
                >
                  <span>Choose New Password</span>
                </h6>
              </div>
              <div class="card-content" *ngIf="!loading">
                <div
                  *ngIf="invalidCode"
                  class="bs-callout-danger callout-border-left p-1"
                >
                  {{ invalidCode }}
                </div>

                <div class="card-body" *ngIf="!invalidCode">
                  <form
                    class="form-horizontal"
                    [formGroup]="form"
                    (ngSubmit)="submit()"
                  >
                    <div class="bs-callout-info callout-border-left p-1 mb-2">
                      Please choose a new password
                    </div>

                    <fieldset
                      class="form-group position-relative has-icon-left mb-0"
                    >
                      <div class="row">
                        <div class="col pr-0">
                          <input
                            [type]="viewPassword ? 'text' : 'password'"
                            placeholder="Enter New Password"
                            formControlName="password"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid': submitted && f.password.errors
                            }"
                            required
                          />
                          <div
                            *ngIf="submitted && f.password.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="f.password.errors.required">
                              New Password is required
                            </div>

                            <div *ngIf="f.password.errors.pattern">
                              Invalid password, password must:<br />
                              Contain at least one digit [0-9]<br />
                              Contain at least one lowercase character [a-z]<br />
                              Contain at least one uppercase character [A-Z]<br />
                              Contain at least one special character
                              <span [innerHTML]="'$@$!%*?&'"></span><br />
                              Be at least 8 characters in length, but no more
                              than 32.<br />
                            </div>
                          </div>
                        </div>
                        <div class="col col-auto pl-0 pr-0">
                          <a
                            class="btn btn-light"
                            style="margin-top: 0px !important;"
                            (click)="toggleViewPassword()"
                          >
                            <i class="fa fa-eye-slash" *ngIf="viewPassword"></i>
                            <i class="fa fa-eye" *ngIf="!viewPassword"></i>
                          </a>
                        </div>
                      </div>

                      <div class="form-control-position">
                        <i class="la la-key"></i>
                      </div>
                    </fieldset>

                    <fieldset
                      class="form-group position-relative has-icon-left"
                    >
                      <div class="row">
                        <div class="col pr-0">
                          <input
                            [type]="viewPassword ? 'text' : 'password'"
                            placeholder="Confirm New Password"
                            formControlName="confirm_password"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid':
                                submitted && f.confirm_password.errors
                            }"
                            required
                          />
                        </div>
                      </div>

                      <div class="form-control-position">
                        <i class="la la-key"></i>
                      </div>
                      <div
                        *ngIf="submitted && f.confirm_password.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.confirm_password.errors.required">
                          Confirm Password is required
                        </div>
                        <div *ngIf="f.confirm_password.errors.matchOther">
                          Passwords don't match
                        </div>
                      </div>
                    </fieldset>

                    <button
                      type="submit"
                      class="btn btn-outline-info btn-block mr-0"
                    >
                      <i class="fa fa-refresh fa-spin" *ngIf="loading"></i>
                      <i class="feather ft-unlock" *ngIf="!loading"></i>
                      Save Password
                    </button>
                  </form>
                </div>
              </div>
              <app-loader *ngIf="loading"></app-loader>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
