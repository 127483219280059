import { ChangeDetectorRef, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// import { AngularFireAuth } from "angularfire2/auth";
// import * as firebase from "firebase/app";
import { environment } from "../../environments/environment";
import { AuthModel } from "../_models/auth.model";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { DocumentsService } from "./documents/documents.service";
import { UserModel } from "../_models/user.model";
import { BehaviorSubject, Observable, of } from "rxjs";
import { AcademicYearsService } from "./academic-years/academic-years.service";
import { SchoolsService } from "./schools/schools.service";
import { Location } from "@angular/common";
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from "@azure/msal-angular";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SurveyModalComponent } from "../_components/modals/survey-modal/survey-modal.component";

@Injectable()
export class AuthService {
  helper = new JwtHelperService();
  currentUser: UserModel;
  public loading = true;
  backLinkSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  backLink$: Observable<any> = this.backLinkSubject.asObservable();
  currentUrlSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentUrl$: Observable<any> = this.currentUrlSubject.asObservable();

  get currentUserValue(): UserModel {
    return this.currentUser;
  }

  set currentUserValue(user: UserModel) {
    if (user) {
      sessionStorage.setItem("currentUser", JSON.stringify(user));
    }
    this.currentUser = user;
    //set previous url as login
    sessionStorage.setItem("previousUrl", this.router.url);
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private documentsService: DocumentsService,
    private schoolService: SchoolsService,
    private academicYearsService: AcademicYearsService,
    private msalService: MsalService,
    private modalService: NgbModal
  ) {}

  async registerPasswordLogin(user) {
    return await this.http
      .post<any>(`${environment.api.url}/auth/password/register-login`, user)
      .toPromise();
  }

  /**
   *
   * @param idToken the token passed from microsoft
   * @returns user
   */
  async microsoftLogin(idToken) {
    return await this.http
      .get<any>(`${environment.api.url}/auth/microsoft/login`, {
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .toPromise();
  }
  async registerMicrosoftLogin(idToken, user) {
    return await this.http
      .post<any>(`${environment.api.url}/auth/microsoft/register-login`, user, {
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .toPromise();
  }

  /**
   *
   * @param idToken the token passed from google
   * @returns user
   */
  async googleLogin(idToken) {
    return await this.http
      .get<any>(`${environment.api.url}/auth/google/login`, {
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .toPromise();
  }

  //for register page
  async registerGoogleLogin(idToken, user) {
    return await this.http
      .post<any>(`${environment.api.url}/auth/google/register-login`, user, {
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .toPromise();
  }

  /**
   * Allows the user to login if they exist in the system
   * @param token from microsoft/google/sixIntoSevenDB user
   */
  async login(token) {
    this.loading = true;
    const decodedToken = this.helper.decodeToken(token.access_token);
    const authModel = new AuthModel();
    authModel.setAuth({
      authToken: token.access_token,
      expires: decodedToken.exp,
    });

    let routerLink = null;

    if (
      !decodedToken.global_admin &&
      decodedToken.school &&
      decodedToken.school.signed_documents
    ) {
      routerLink = "/school/dashboard";
      decodedToken.school.has_unsigned_documents = false;
      const latestDocumentVersionIDs: any = await this.documentsService.getLatestDocumentVersionIDs(
        decodedToken.school._id
      );
      decodedToken.school.has_unsigned_documents = false;

      for (const latestDocumentVersionID of latestDocumentVersionIDs) {
        if (
          !decodedToken.school.signed_documents.includes(
            latestDocumentVersionID
          )
        ) {
          decodedToken.school.has_unsigned_documents = true;
        }
      }
      if (
        decodedToken.school.has_unsigned_documents &&
        !decodedToken.global_admin
      ) {
        routerLink = "/school/documents-sign";
      }
    }
    this.setAuthFromLocalStorage(authModel);
    this.setUserByToken(decodedToken);
    sessionStorage.removeItem("currentLayoutStyle");
    this.loading = false;
    if (decodedToken.school_group) {
      routerLink = "/school-group/dashboard";
    }
    if (decodedToken.global_admin) {
      routerLink = "/global-admin/dashboard";
    }
    this.router.navigate([routerLink]).then(async () => {
      if (this.currentUser.school && !this.currentUser.school.survey_complete) {
        const modalRef = this.modalService.open(SurveyModalComponent, {
          size: "lg",
          windowClass: "modal-xl",
        });
        const confirmed = await modalRef.result;
        if (confirmed) {
          window.location.href = sessionStorage.getItem("previousUrl");
        }
      } else {
        return;
      }
    });
  }

  //register login
  async registerLogin(token) {
    this.loading = true;
    const decodedToken = this.helper.decodeToken(token.access_token);
    const authModel = new AuthModel();
    authModel.setAuth({
      authToken: token.access_token,
      expires: decodedToken.exp,
    });

    //set the router link to go to documents sign page
    let routerLink = "/school/documents-sign";

    this.setAuthFromLocalStorage(authModel);
    this.setUserByToken(decodedToken);
    sessionStorage.removeItem("currentLayoutStyle");
    this.loading = false;

    this.router.navigate([routerLink]);
  }

  logout(url = null) {
    sessionStorage.clear();
    this.currentUserValue = undefined;
    this.router.navigate(["/auth/login"], {
      queryParams: url,
    });
  }

  // getUserByToken(): Observable<UserModel> {
  setUserByToken(token) {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const user = new UserModel();
    user.setUser(token);
    this.currentUserValue = user;
    return user;
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      sessionStorage.setItem("auth_token", JSON.stringify(auth));
      return true;
    }
    return false;
  }

  public getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(sessionStorage.getItem("auth_token"));
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async setGlobalAdmin() {
    const user = new UserModel();
    this.currentUserValue.school = null;
    this.currentUserValue.school_group = null;
    this.currentUserValue.academic_year = null;
    user.setUser(this.currentUserValue);
    this.currentUserValue = user;
    this.router.navigate(["/global-admin/dashboard"]);
  }

  async selectSchool(school) {
    const user = new UserModel();
    this.currentUserValue.school_group = null;

    school.has_unsigned_documents = false;

    const latestDocumentVersionIDs: any = await this.documentsService.getLatestDocumentVersionIDs(
      school._id
    );

    school.has_unsigned_documents = false;

    for (const latestDocumentVersionID of latestDocumentVersionIDs) {
      if (!school.signed_documents.includes(latestDocumentVersionID)) {
        school.has_unsigned_documents = true;
      }
    }
    if (school.has_unsigned_documents && !school.global_admin) {
      this.router.navigate(['"/school/documents-sign"']);
    }

    this.currentUserValue.school = {
      _id: school._id,
      name: school.name,
      phase_of_education: school.phase_of_education,
      school_sync_last_date_completed: school.school_sync_last_date_completed,

      has_unsigned_documents: school.has_unsigned_documents,
    };
    this.currentUserValue.academic_year = await this.academicYearsService.getCurrentAcademicYear();

    user.setUser(this.currentUserValue);
    this.currentUserValue = user;
    window.location.href = "/school/dashboard";
  }

  async selectAcademicYear(academicYear) {
    const user = new UserModel();
    this.currentUserValue.academic_year = academicYear;
    user.setUser(this.currentUserValue);
    this.currentUserValue = user;
    window.location.href = "/school/dashboard";
  }

  async selectSchoolGroup(schoolGroup) {
    const user = new UserModel();
    this.currentUserValue.school_group = {
      _id: schoolGroup._id,
      name: schoolGroup.name,
    };

    this.currentUserValue.academic_year = await this.academicYearsService.getCurrentAcademicYear();
    this.currentUserValue.school = null;
    user.setUser(this.currentUserValue);
    this.currentUserValue = user;
    sessionStorage.removeItem("attendance_preset");
    window.location.href = "/school-group/dashboard";
  }

  // Register
  // doRegister(value) {
  //   return new Promise<any>((resolve, reject) => {
  //     firebase
  //       .auth()
  //       .createUserWithEmailAndPassword(value.email, value.password)
  //       .then(
  //         (res) => {
  //           resolve(res);
  //         },
  //         (err) => reject(err)
  //       );
  //   });
  // }

  // Login
  // doLogin(value) {
  //     return new Promise<any>((resolve, reject) => {
  //         firebase
  //             .auth()
  //             .signInWithEmailAndPassword(value.email, value.password)
  //             .then(
  //                 (res) => {
  //                     resolve(res);
  //                 },
  //                 (err) => reject(err)
  //             );
  //     });
  // }

  newLogin(value) {
    return this.http
      .post(environment.api.url + "/auth/login", value)
      .toPromise();
  }

  changePassword(value) {
    return this.http
      .post(environment.api.url + "/auth/change-password", value)
      .toPromise();
  }

  // Logout
  /**
   *
   * @param code the reason for logging the user out
   * @returns the user to the login page
   */
  doLogout(code = null) {
    let loginType = sessionStorage.getItem("login_type");

    // await this.msalService.instance.setActiveAccount(result.account);
    if (loginType == "Microsoft") {
      this.msalService.logoutRedirect();
    }
    return new Promise((resolve, reject) => {
      this.router.navigate(["/login"], {
        queryParams: code,
      });
      sessionStorage.clear();
      resolve(true);
      // }
    });
  }
}
