import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { DateHelperService } from "../../_helpers/date-helper.service";

@Injectable({
  providedIn: "root",
})
export class DynamicFormsService {
  constructor(private http: HttpClient, public dateHelper: DateHelperService) {}

  //get all
  index() {
    return this.http
      .get<any>(environment.api.url + "/dynamic-forms")
      .toPromise();
  }

  //get the survey form for schools
  getSurveyForm(data) {
    return this.http
      .post(environment.api.url + "/dynamic-forms/get-survey", data)
      .toPromise();
  }

  //get one
  single(id: any) {
    return this.http
      .get<any>(environment.api.url + "/dynamic-forms/dynamic-form/" + id)
      .toPromise();
  }

  //CRUD
  add(form: any) {
    return this.http
      .post(environment.api.url + "/dynamic-forms/add", form)
      .toPromise();
  }

  edit(id: any, form: any) {
    return this.http
      .post(
        environment.api.url + "/dynamic-forms/dynamic-form/" + id + "/edit",
        form
      )
      .toPromise();
  }

  delete(id: string) {
    return this.http
      .get(environment.api.url + "/dynamic-forms/delete-dynamic-form/" + id)
      .toPromise();
  }

  /**
   * Saving form fields
   */
  addFormFields(model: any) {
    return this.http
      .post(environment.api.url + "/dynamic-forms/form-fields", model)
      .toPromise();
  }

  //....................SURVEY ANALYSIS....................
  //get users filled in
  surveyAnalysis() {
    return this.http
      .get<any>(environment.api.url + "/dynamic-forms/survey-analysis")
      .toPromise();
  }

  //get multi choice chart data
  multipleChoiceBarCharts(body) {
    return this.http
      .post(environment.api.url + "/dynamic-forms/multiple-choice-bar-charts", {
        questionIDs: body[0],
        optionValues: body[1],
      })
      .toPromise();
  }

  singleSelectPieCharts(questionIDs) {
    return this.http
      .post(
        environment.api.url + "/dynamic-forms/single-select-pie-charts/",
        questionIDs
      )
      .toPromise();
  }

  textBoxes(questionIDs) {
    return this.http
      .post(environment.api.url + "/dynamic-forms/text-boxes/", questionIDs)
      .toPromise();
  }

  duplicateForm(formID: any) {
    return this.http
      .get(environment.api.url + "/dynamic-forms/duplicate/" + formID)
      .toPromise();
  }
}
