import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class GlobalSettingsService {
	constructor(private http: HttpClient) {}

	async getGlobalSettings() {
		const globalSettings = await this.http
			.get<any>(environment.api.url + "/global-settings")
			.toPromise();
		return globalSettings;
	}
}
