import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AcademicYearsService } from "../../../_services/academic-years/academic-years.service";
import { DateHelperService } from "../../../_helpers/date-helper.service";
import { SchoolsService } from "src/app/_services/schools/schools.service";

@Component({
  selector: "app-academic-year-selector-modal",
  templateUrl: "./academic-year-selector-modal.component.html",
  styleUrls: ["./academic-year-selector-modal.component.css"],
})
export class AcademicYearSelectorModalComponent implements OnInit {
  loading = true;
  currentUser: any;

  perPage = 5;
  currentPage = 1;
  totalItems = 0;
  sortDesc = false;
  searchKeys = ["id", "name"];
  filterVal = "";
  sortBy = "name";

  previousUrl: string;

  globalAdmin: boolean;
  hasSchoolGroup = true;

  versions: any = [];

  items: any;
  institutionID: string;
  groupID: string;

  constructor(
    public activeModal: NgbActiveModal,
    private academicYearsService: AcademicYearsService,
    private schoolsService: SchoolsService,
    public dateHelper: DateHelperService
  ) {}

  async ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (this.currentUser.global_admin === "true") {
      this.globalAdmin = true;
    }
    this.groupID = sessionStorage.getItem("groupID");
    this.versions = await this.academicYearsService.index();
    this.loading = false;
  }

  async selectAcademicYear(academicYear) {
    this.loading = true;
    this.currentUser.academic_year = academicYear;

    sessionStorage.setItem("currentUser", JSON.stringify(this.currentUser));
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.close(false);
  }
}
