import { Component, OnInit } from "@angular/core";
import { DocumentSignModalComponent } from "../../../_components/modals/document-sign-modal/document-sign-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DocumentsService } from "../../../_services/documents/documents.service";
import { Router } from "@angular/router";
import { SchoolsService } from "src/app/_services/schools/schools.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UsersService } from "src/app/_services/users/users.service";
import { ToastrService } from "ngx-toastr";

@Component({
  templateUrl: "documents-sign.component.html",
  styleUrls: ["./documents-sign.component.css"],
})
export class DocumentsSignComponent implements OnInit {
  public submitted = false;
  currentUser;
  loading = true;
  expand = true;
  documents = [];

  //for selecting a transition lead
  leadType = "transitionLead";
  transitionLeadForm: FormGroup;
  public submittedLead = false;
  transitionLeadUser: any = {};
  emailSentToTransitionLead = false;

  constructor(
    private modalService: NgbModal,
    private documentsService: DocumentsService,
    private schoolService: SchoolsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    this.loading = true;

    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    if (!this.currentUser.global_admin && this.currentUser.school) {
      this.documents = await this.documentsService.getUnsignedDocuments(
        this.currentUser.school._id
      );

      if (!this.documents.length) {
        this.submitted == true;
      }
    }

    //create transition lead form
    this.transitionLeadForm = this.formBuilder.group({
      forename: [null, []],
      surname: [null, []],
      email: [null, []],
    });

    this.loading = false;
  }

  allDocumentsSigned() {
    for (let document of this.documents) {
      if (!document.signed) {
        return false;
      }
    }
    return true;
  }

  previewDocument(document) {
    const modalRef = this.modalService.open(DocumentSignModalComponent, {
      size: "lg",
      windowClass: "full-screen-modal",
    });
    modalRef.componentInstance.document = this.documentsService.buildDocument(
      document,
      this.currentUser.school,
      this.currentUser
    );

    modalRef.result.then((result) => {
      if (result) {
        document.signed = true;
      }
    });
  }

  async submitDocuments() {
    this.loading = true;
    let signedDocuments = [];
    for (let document of this.documents) {
      signedDocuments.push(document._id);
    }
    await this.documentsService.addDocumentsSchools(
      signedDocuments,
      this.currentUser.school._id
    );

    let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (currentUser.school && currentUser.school.has_unsigned_documents) {
      currentUser.school.has_unsigned_documents = false;
    }
    sessionStorage.setItem("currentUser", JSON.stringify(currentUser));

    //when the documents have been signed, this sets the school as registered
    await this.schoolService.registerSchool(currentUser.school._id);
    this.loading = false;
    this.submitted = true;
  }

  get documentsSigned() {
    for (const document of this.documents) {
      if (!document.signed) {
        return false;
      }
    }
    return true;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.transitionLeadForm.controls;
  }

  changeLeadType() {
    //if the leadType is the same person, set the transition lead form fields as not required
    if (this.leadType == "transitionLead") {
      this.transitionLeadForm.controls["forename"].clearValidators();
      this.transitionLeadForm.controls["surname"].clearValidators();
      this.transitionLeadForm.controls["email"].clearValidators();
    }

    //if the leadType is someone else, set the transition lead form fields as required
    if (this.leadType == "notTransitionLead") {
      this.transitionLeadForm.controls["forename"].setValidators([
        Validators.required,
      ]);
      this.transitionLeadForm.controls["surname"].setValidators([
        Validators.required,
      ]);
      this.transitionLeadForm.controls["email"].setValidators([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]+$"),
      ]);
    }
  }

  async addTransitionLead() {
    this.submittedLead = true;

    // if form has errors, then return
    if (this.transitionLeadForm.invalid) {
      return;
    }

    this.transitionLeadUser.forename = this.transitionLeadForm.value.forename;
    this.transitionLeadUser.surname = this.transitionLeadForm.value.surname;
    this.transitionLeadUser.email = this.transitionLeadForm.value.email;

    await this.usersService.addTransitionLead(this.transitionLeadUser);
    this.emailSentToTransitionLead = true;
    this.toastr.success(
      "Email has been sent to your transition lead for them to sign in."
    );
  }

  async goToLogin() {
    this.router.navigate(["/school/dashboard"]);
  }

  async loginPage() {
    this.router.navigate(["/login"]);
  }
}
