import { AuthModel } from "./auth.model";

export class UserModel extends AuthModel {
  _id: string;
  forename: string;
  surname: string;
  displayName: string;
  email: any[];
  last_login: Date;
  global_admin: boolean;
  school: any;
  school_group: any;
  academic_year: any;
  signed_documents: any = [];

  setUser(user: any) {
    this._id = user.sub || user._id;
    this.forename = user.forename || "";
    this.surname = user.surname || "";
    this.displayName =
      user.forename && user.surname ? `${user.forename} ${user.surname}` : "";
    this.email = user.email || "";
    this.last_login = user.last_login || new Date();
    this.global_admin = user.global_admin || false;
    this.school = user.school || null;
    this.school_group = user.school_group || null;
    this.academic_year = user.academic_year || null;
    this.signed_documents = user.signed_documents || [];
  }
}
