<div class="modal-header">
  <h4 class="modal-title">
    {{ title }}
  </h4>
  <a (click)="close()"
    ><i class="fa fa-times tutorial-close text-success"></i>
  </a>
</div>
<div class="modal-body">
  <div class="bs-callout-danger callout-border-left p-1">
    <div [innerHTML]="body"></div>
  </div>
</div>
<div class="modal-footer">
  <button
    *ngIf="retry"
    type="button"
    class="btn btn-size grey btn-success"
    (click)="submit()"
  >
    Retry
  </button>
  <button
    type="button"
    class="btn btn-size btn-outline-secondary"
    (click)="close()"
  >
    Close
  </button>
</div>
