import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SupportService } from "src/app/_services/support/support.service";

@Component({
  selector: "support-request-modal",
  templateUrl: "./support-request-modal.component.html",
  styleUrls: ["./support-request-modal.component.css"],
})
export class SupportRequestModalComponent implements OnInit {
  form: FormGroup;
  currentUser;
  submitted = false;
  issueTypes: any;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private supportService: SupportService
  ) {}

  async ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    let userName = this.currentUser.forename + " " + this.currentUser.surname;
    this.form = this.formBuilder.group({
      userName: [userName, Validators.required],
      userEmail: [this.currentUser.email, Validators.required],
      schoolName: [
        this.currentUser.school
          ? this.currentUser.school.establishment_name
          : this.currentUser.school_group.name,
        Validators.required,
      ],
      description: [null, Validators.required],
    });
  }

  get f() {
    return this.form.controls;
  }

  close() {
    this.activeModal.close(false);
  }
  submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.supportService.sendSupportEmail(this.form.value);
    this.activeModal.close(true);
  }
}
