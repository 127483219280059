<div class="modal-header">
  <h4 class="modal-title">
    {{ document.name }}
  </h4>
  <a (click)="close()"
    ><i class="fa fa-times tutorial-close text-success"></i>
  </a>
</div>
<div class="modal-body" [innerHTML]="document.document"></div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-size btn-outline-secondary"
    (click)="close()"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-size grey btn-success"
    (click)="submit()"
  >
    Accept
  </button>
</div>
