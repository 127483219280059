<div class="app-content content login-page">
  <div class="content-wrapper">
    <div class="content-body">
      <section class="flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-md-6 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
              <div class="card-header border-0 pb-0">
                <div class="card-title text-center">
                  <img
                    class="login-logo"
                    src="assets/images/logo/logo.svg"
                    alt="branding logo"
                  />
                </div>
                <h6
                  class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"
                >
                  <span>Forgot Password</span>
                </h6>
              </div>
              <div class="card-content">
                <div class="card-body" *ngIf="!loading">
                  <div
                    *ngIf="passwordResetSent"
                    class="bs-callout-success callout-border-left p-1"
                  >
                    Password reset request has been sent, if a matching email
                    address is found on SixIntoSeven you will recieve an email
                    allowing you to reset your password. Password resets are
                    valid for 24 hours.
                  </div>

                  <form
                    *ngIf="!passwordResetSent"
                    class="form-horizontal"
                    [formGroup]="form"
                    (ngSubmit)="submit()"
                  >
                    <div class="bs-callout-info callout-border-left p-1 mb-2">
                      Please enter your email, if a user with a matching email
                      is found you will receive an email with a password reset
                      link, which will be active for 24 hours.
                    </div>

                    <!-- <div
                      *ngIf="submitted && !emailExists"
                      class="bs-callout-danger callout-border-left p-1 mb-2"
                    >
                      Sorry but that email does not exist in the system
                    </div> -->

                    <fieldset
                      class="form-group position-relative has-icon-left"
                    >
                      <input
                        type="email"
                        placeholder="Enter Email"
                        formControlName="email"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid': submitted && f.email.errors
                        }"
                      />
                      <div class="form-control-position">
                        <i class="la la-key"></i>
                      </div>
                      <div
                        *ngIf="submitted && f.email.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.email.errors.required">
                          Email is required
                        </div>
                      </div>
                    </fieldset>

                    <button
                      type="submit"
                      class="btn btn-size btn-info btn-block"
                      [disabled]="f.email.errors"
                    >
                      <i class="fa fa-refresh fa-spin" *ngIf="loading"></i>
                      <i class="feather ft-unlock" *ngIf="!loading"></i>
                      Request Password Reset
                    </button>
                  </form>
                </div>
                <div class="card-body" *ngIf="loading">
                  <app-loader></app-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
