<nav
  class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-shadow bg-white mb-0"
  [ngClass]="selectedHeaderNavBarClass"
>
  <div class="navbar-wrapper">
    <div
      id="navbar-header"
      class="navbar-header"
      [ngClass]="selectedNavBarHeaderClass"
      (mouseenter)="mouseEnter($event)"
      (mouseleave)="mouseLeave($event)"
    >
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item mobile-menu d-lg-none mr-auto">
          <a
            class="nav-link nav-menu-main menu-toggle hidden-xs11"
            (click)="toggleNavigation($event)"
          >
            <i class="feather ft-menu font-large-1"></i
          ></a>
        </li>
        <li class="nav-item mr-auto">
          <a class="navbar-brand" routerLink="/school/dashboard"
            ><img
              *ngIf="_themeSettingsConfig && _themeSettingsConfig.brand"
              class="brand-logo"
              alt="s logo"
              [src]="_themeSettingsConfig.brand.logo.value"
            />
            <h3
              *ngIf="_themeSettingsConfig && _themeSettingsConfig.brand"
              class="brand-text d-none"
            >
              {{ _themeSettingsConfig.brand.brand_name }}
            </h3>
          </a>
        </li>
        <li class="nav-item d-lg-none">
          <a
            class="nav-link open-navbar-container"
            data-toggle="collapse"
            data-target="#navbar-mobile"
            (click)="toggleNavbar($event)"
            ><i class="la la-ellipsis-v"></i
          ></a>
        </li>
      </ul>
    </div>
    <div class="navbar-container content" [hidden]="isMobile && !showNavbar">
      <div class="collapse navbar-collapse d-flex" id="navbar-mobile">
        <div class="col-1" style="padding-right: 0px !important">
          <div
            class="progress-nav row ml-0"
            *ngIf="!(authService.currentUrl$ | async).includes('dashboard')"
          >
            <a class="progress-nav-prev" (click)="back()">
              <fa-icon [icon]="faChevronLeft" class="mr-05"></fa-icon
              >{{ backLinkText }}
            </a>
          </div>
        </div>

        <div class="col" style="padding-left: 0px; padding-right: 0px">
          <div class="row alertBoxPink" *ngIf="this.alert">
            <div class="col-1" style="flex: 0% !important">
              <fa-icon
                class="WarningIcon"
                [icon]="faExclamationTriangle"
              ></fa-icon>
            </div>
            <div class="col" style="padding-left: 0px; padding-right: 0px">
              <p *ngIf="this.currentUser.school_group">
                {{ this.alert.group_message }}
              </p>
              <p
                *ngIf="
                  this.currentUser.school &&
                  this.currentUser.school.phase_of_education == 'Primary'
                "
              >
                {{ this.alert.primary_message }}
              </p>
              <p
                *ngIf="
                  this.currentUser.school &&
                  this.currentUser.school.phase_of_education == 'Secondary'
                "
              >
                {{ this.alert.secondary_message }}
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="col-1">
          <a (click)="openSurvey()">
            <fa-icon [icon]="faBell" class="mr-1"></fa-icon>
          </a>
        </div> -->
        <div class="col-1">
          <ul
            class="nav navbar-nav float-right"
            *ngIf="this.currentUser.school"
          >
            <li
              class="dropdown-user nav-item"
              ngbDropdown
              #myDrop="ngbDropdown"
              (openChange)="notificationTab($event)"
            >
              <a class="nav-link" ngbDropdownToggle>
                <div class="icon-wrapper mb-2">
                  <fa-icon
                    [icon]="faBell"
                    class="font-medium-5 pull-right"
                    style="color: navy"
                  ></fa-icon>
                  <span
                    *ngIf="this.unreadNotifications.length > 0"
                    class="badge"
                    >{{ this.unreadNotifications.length }}</span
                  >
                </div>
              </a>
              <div
                ngbDropdownMenu
                class="dropdown-menu-notification dropdown-menu-right"
              >
                <ng-container *ngIf="this.notifications.length == 0 && !survey">
                  <a class="dropdown-item">
                    <fa-icon [icon]="faBell" class="mr-1"></fa-icon>
                    No notifications
                  </a>
                </ng-container>
                <ng-container *ngIf="this.notifications.length == 0 && survey">
                  <a class="dropdown-item">
                    <a class="dropdown-item-notification" *ngIf="survey">
                      <div class="row">
                        <div class="col">
                          <b *ngIf="survey.read == false"> {{ survey.name }}</b>
                        </div>
                        <div class="col text-right">
                          <small>
                            <b>{{ dateHelper.formatDate(survey.date) }}</b>
                          </small>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p>{{ survey.description }}</p>
                        </div>
                        <div class="col-5">
                          <a
                            (click)="openSurvey()"
                            class="btn btn-secondary m-0"
                            >{{ survey.buttonText }}</a
                          >
                        </div>
                      </div>
                    </a>
                  </a>
                </ng-container>
                <ng-container *ngIf="this.notifications.length > 0">
                  <a class="dropdown-item-notification" *ngIf="this.survey">
                    <div class="row">
                      <div class="col">
                        <b *ngIf="survey.read == false"> {{ survey.name }}</b>
                        <h6 *ngIf="survey.read == true">
                          {{ survey.name }}
                        </h6>
                      </div>
                      <div class="col text-right">
                        <small>
                          <b>{{ dateHelper.formatDate(survey.date) }}</b>
                        </small>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p>{{ survey.description }}</p>
                      </div>
                      <div class="col-5">
                        <a (click)="test()" class="btn btn-secondary m-0">{{
                          survey.buttonText
                        }}</a>
                      </div>
                    </div>
                  </a>
                  <ng-container *ngFor="let notification of this.notifications">
                    <a class="dropdown-item-notification">
                      <div class="row">
                        <div class="col">
                          <b *ngIf="notification.read == false">
                            {{ notification.name }}</b
                          >
                          <h6 *ngIf="notification.read == true">
                            {{ notification.name }}
                          </h6>
                        </div>
                        <div class="col text-right">
                          <small>
                            <b>{{
                              dateHelper.formatDate(notification.date)
                            }}</b>
                          </small>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p>{{ notification.description }}</p>
                        </div>
                        <div class="col-5" *ngIf="notification.button != null">
                          <a
                            (click)="myDrop.close()"
                            routerLink="{{ notification.button }}"
                            class="btn btn-secondary m-0"
                            >{{ notification.buttonText }}</a
                          >
                        </div>
                      </div>
                    </a>
                    <div class="dropdown-divider"></div>
                  </ng-container>
                </ng-container>
              </div>
            </li>
          </ul>
        </div>

        <div class="col-auto p-0 pl-5">
          <div class="row mx-0">
            <ul class="nav navbar-nav float-right">
              <li class="dropdown-user nav-item" ngbDropdown>
                <a class="nav-link dropdown-user-link" ngbDropdownToggle>
                  <span
                    *ngIf="currentUser.displayName"
                    class="mr-1 user-name text-right"
                  >
                    <span class="display-name">{{
                      currentUser.displayName
                    }}</span
                    ><br />

                    <span
                      *ngIf="
                        currentUser.school &&
                        !currentUser.school_group &&
                        currentUser.school.establishment_name
                      "
                      class="small"
                      >{{ currentUser.school.establishment_name }}</span
                    >

                    <!-- if the school is part of a school group -->
                    <span
                      *ngIf="
                        currentUser.school &&
                        !currentUser.school_group &&
                        currentUser.school.establishment_name &&
                        linkedSchoolGroup
                      "
                      class="small"
                    >
                      <br />
                      {{ linkedSchoolGroup.name }}
                    </span>

                    <span *ngIf="currentUser.school_group" class="small">{{
                      currentUser.school_group.name
                    }}</span>

                    <span
                      *ngIf="
                        !currentUser.school &&
                        !currentUser.school_group &&
                        currentUser.global_admin
                      "
                      class="small"
                      >Global Admin</span
                    >
                  </span>
                  <span
                    *ngIf="!currentUser.displayName"
                    class="mr-1 user-name text-bold-700"
                    >Unknown User</span
                  >
                  <span class="avatar avatar-online">
                    <img *ngIf="logo" [src]="logo" alt="avatar" />

                    <img
                      *ngIf="!logo && currentUser.school"
                      class="avatarLogoSchool"
                      src="../../../assets/images/portrait/small/default.png"
                      alt="avatar"
                    />
                    <img
                      *ngIf="!logo && !currentUser.school"
                      class="avatarLogo"
                      src="../../../assets/images/portrait/small/default.png"
                      alt="avatar"
                    />
                    <i></i>
                  </span>
                </a>
                <div
                  ngbDropdownMenu
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownProfileMenu"
                >
                  <a class="dropdown-item" (click)="schoolSelect()">
                    <fa-icon [icon]="faUniversity" class="mr-1"></fa-icon>
                    Select School
                  </a>
                  <a class="dropdown-item" (click)="groupSelect()"
                    ><fa-icon [icon]="faCity" class="mr-1"></fa-icon> Select
                    Group
                  </a>
                  <a
                    *ngIf="currentUser.global_admin"
                    class="dropdown-item bg-primary text-white"
                    (click)="selectGlobalAdmin()"
                  >
                    <fa-icon [icon]="faCrown" class="mr-1"></fa-icon> Global
                    Admin
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" [routerLink]="" (click)="logout()"
                    ><i class="feather ft-power"></i> Logout</a
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
