import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../../_services/auth.service";
import { AlertService } from "../../../_services/alert.service";
import { UsersService } from "src/app/_services/users/users.service";
@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  loading = false;
  emailExists = true;
  submitted = false;
  isPageLoaded = false;
  returnUrl: string;
  passwordResetSent = false;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public authService: AuthService,
    public userService: UsersService
  ) {}

  async ngOnInit() {
    this.form = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
          ),
        ],
      ],
    });
    this.isPageLoaded = true;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  async submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    try {
      await this.userService.sendPasswordReset(
        this.form.value.email.toLowerCase().trim()
      );
    } catch (error) {
      this.submitted = false;
      this.alertService.error(error.message);
    }

    this.loading = false;

    this.passwordResetSent = true;
  }
}
